<template>
    <div id="main-contain fs--1" class="main-contain py-3">
        <div class="container">
            <div class="news-scroll-contain">
                <div class="fl-x section-title w-8re text-white fs--1 font-weight-medium pos-r">
                    <span class="pos-r fl-x fl-a-c">
                        <span class="fs-lg--1 font-lato-semi-bold">WHATS NEW</span>
                        <span class="fl-x fl-a-c ml-1">
                            <img class="" src="../../assets/web/landing/icons8-double-right-16.png"
                                 alt=""></span>
                    </span>
                    <div class="text-primary"/>
                </div>
                <span class="fl-x news-items text-white">
                <marquee class="cursor-pointer" behavior="scroll" direction="left">
                    <div class="fl-x" v-if="details.length!==0">
                        <div @click="$router.push('/news/' + item.id)" class="fl-x fl-a-c px-2"
                             v-for="(item,index) in details" :key="index">
                            <span class="avatar-container mr-3">
                                <div class="bullet"></div>
                            </span>
                            <span class="fs-1 font-weight-medium font-lato-medium">{{ item.title }}</span>
                        </div>
                    </div>
                    <div class="fl-x" v-else>
                        <div @click="$router.push('/course-offered/')" class="fl-x fl-a-c px-2">
                            <span class="avatar-container mr-3">
                                <div class="bullet"></div>
                            </span>
                            <span class="fs-1 font-weight-medium font-lato-medium">Explore our wide variety of courses offered.</span>
                        </div>
                    </div>
            </marquee>
            </span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'WhatsNewScrolling',
    props: { details: { type: Array, default: null } }
};
</script>

<style scoped lang="scss">

.bullet {
    width: 0.4rem;
    height: 0.4rem;
    background-color: var(--color-white);
    border-radius: 50%;
}

.main-contain {
    //position: fixed;
    position: relative;
    font-family: Arial;
    top: -1px;
    left: 0;
    //background: rgba(0, 0, 0, 0.8);
    //background: var(--bg-1) !important;
    z-index: 990;
    //height: 2rem !important;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    background: url("../../assets/web/landing/green-strip.jpg");

    ::before {
        content: "";
        position: absolute;
        left: 0;
        //background-color: var(--bg-2);
        height: 0.6rem;
        width: 100%;
        z-index: -999;
    }

}

.root-contain {
    width: 100vw;
    position: relative;

    ::before {
        content: "";
        position: absolute;
        left: 0;
        //background-color: var(--bg-2);
        height: 1rem;
        width: 100%;
        z-index: 990;
    }
}

.news-scroll-contain {
    //position: absolute;
    //top: 50%;
    //top: 147px;
    //top: 140px;
    //left: 50%;
    //transform: translate(-50%, -50%);
    width: 100%;
    //max-width: var(--container-max-width-xl);
    padding: 0;
    display: flex;
    justify-content: center;
    @media (min-width: 768px) and (max-width: 1024px) {
        //top: 125px;
    }

    .section-title {
        position: relative;
        width: 40%;
        @media (min-width: 992px) {
            width: 10%;
        }

        &::after {
            content: "";
            position: absolute;
            right: 0;
            //background: url("../../assets/mobile/home/double-arrow.png");
            height: 0.8rem;
            width: auto;
        }
    }

    @media (min-width: 992px) {
        //top: 150px;
        //padding: 0 100px;
    }

    .news-items {
        width: 90%;
    }
}

.breakingtitle {
    font-size: 50px;
    background: black;
    color: #FFD700;
    padding: 10px;
}

#readmorebutton {
    background: black;
    color: #FFD700;
    border-style: none;
    padding: 20px;
    font-size: 20px;
}

.marquee {
    color: black;
    letter-spacing: 2px;
    font-weight: 700;
    padding: 30px;
    font-size: 40px;
}

</style>
