<template>
    <vue-table-card title="Banner" :show-search-box="false" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                    :extra-params="{pages}">
        <template #buttons>
            <div class="btn-group">
                <drop-btn text="Filters" icon="fa fa-filter" size="sm" dropdown-width="30r">
                    <div class="m-3">
                        <validated-vue-select name="Banner Type" v-model="pages"
                                              :options="bannerTypeOptions" select-first :clearable="false"/>
                    </div>
                </drop-btn>
                <btn color="success" icon="fa fa-plus" size="sm" @click="$refs.addModal.show()" text="Add"/>
            </div>
        </template>
        <template #banner_image="{rowData}">
            <div class="container-image" @click="viewFile(rowData.banner_image)">
                <img :src="rowData.banner_image" alt="Avatar" class="c-image">
                <div class="c-overlay">
                    <div class="c-icon">
                        <i class="fa fa-eye"></i>
                    </div>
                </div>
            </div>
        </template>
        <template #is_enabled="{rowData}">
            <p v-if="rowData.is_enabled">Yes</p>
            <p v-else>No</p>
        </template>

        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn size="xs" text="View" @click="viewDetails(rowData.id)"/>
                <delete-btn @click="setDelete(rowData)"/>
            </div>
        </template>

        <template #footer>
            <modal title="Add Banner" ref="addModal" width="40r" header-color="primary" no-close-on-backdrop>
                <add-banner @success="formSuccess"/>
            </modal>

            <modal title="Edit Banner" ref="editModal" width="40r" header-color="primary" no-close-on-backdrop>
                <edit-banner :initial-data="editingItem" @success="formSuccess"/>
            </modal>

            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                <p>You are about to delete the banner <b v-html="deletingItem && deletingItem.name"></b>. Are you sure ?
                </p>

                <template #loading>
                    <loading-animation/>
                    Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                </template>
            </delete-modal>
        </template>

    </vue-table-card>
</template>

<script>
import AddBanner from './AddBanner';
import EditBanner from './EditBanner';
import urls from '../../../../data/urls';

export default {
    name: 'Banner',
    components: { EditBanner, AddBanner },
    data () {
        return {
            pages: '',
            listUrl: urls.admin.banner.list,
            addUrl: urls.admin.banner.addEdit,
            deleteUrl: urls.admin.banner.delete,
            editingItem: null,
            deletingItem: null,
            fields: [
                {
                    name: 'order',
                    sortField: 'order',
                    title: 'Order'
                },
                {
                    name: 'page',
                    sortField: 'page',
                    title: 'Page'
                },
                {
                    name: '__slot:banner_image',
                    title: 'Banner Image'
                },
                {
                    name: 'created_at',
                    sortField: 'created_at',
                    title: 'Created At'
                },
                {
                    name: '__slot:is_enabled',
                    title: 'Enabled'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ],
            bannerTypeOptions: [
                { label: 'All', value: '' },
                { label: 'Home', value: 'Home' },
                { label: 'About Us', value: 'About Us' },
                { label: 'Courses Offered', value: 'Courses Offered' },
                { label: 'Training Centers', value: 'Training Centers' },
                { label: 'Contact Us', value: 'Contact Us' }
            ]
        };
    },
    methods: {
        viewFile (file) {
            window.open(file);
        },
        viewDetails (id) {
            this.$router.push('/admin/banner/' + id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.editModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        }
    }
};
</script>
