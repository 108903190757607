<template>
    <vue-table-card title="Training Requests" :show-search-box="false" :fields="fields"
                    :url="listUrl" :show-pagination="false" ref="table">

        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <edit-btn icon="" text="view" @click="viewDetails(rowData)"/>
                <delete-btn @click="setDelete(rowData)"/>
            </div>
        </template>

        <template #name="{rowData}">
            <div v-if="rowData.name">{{rowData.name}}</div>
            <div>{{rowData.organization_name}}</div>
        </template>

        <template #footer>
            <modal title="Details" ref="detailsModal" width="40r" header-color="primary" no-close-on-backdrop>
                <div class="row bg-1 m-2 p-1">
                    <div class="col-6">Training Program</div>
                    <div class="col-6"><span class="pr-3"> : </span>{{details.training_program}}</div>
                </div>
                <div class="row bg-1 m-2 p-1">
                    <div class="col-6">Training Type</div>
                    <div class="col-6"><span class="pr-3"> : </span>{{details.training_type}}</div>
                </div>
                <div class="row bg-1 m-2 p-1" v-if="details.training_type === 'Individual'">
                    <div class="col-6">Name</div>
                    <div class="col-6"><span class="pr-3"> : </span>{{details.name}}</div>
                </div>
                <div class="row bg-1 m-2 p-1" v-if="details.training_type === 'Organization'">
                    <div class="col-6">Organization Name</div>
                    <div class="col-6"><span class="pr-3"> : </span>{{details.organization_name}}</div>
                </div>
                <div class="row bg-1 m-2 p-1" v-if="details.training_type === 'Organization'">
                    <div class="col-6">Number of Participants</div>
                    <div class="col-6"><span class="pr-3"> : </span>{{details.number_of_participants}}</div>
                </div>
                <div class="row bg-1 m-2 p-1">
                    <div class="col-6">Phone</div>
                    <div class="col-6"><span class="pr-3"> : </span>{{details.phone_number}}</div>
                </div>
                <div class="row bg-1 m-2 p-1">
                    <div class="col-6">From Date</div>
                    <div class="col-6"><span class="pr-3"> : </span>{{details.from_date}}</div>
                </div>
                <div class="row bg-1 m-2 p-1">
                    <div class="col-6">To Date</div>
                    <div class="col-6"><span class="pr-3"> : </span>{{details.to_date}}</div>
                </div>
                <div class="row bg-1 m-2 p-1">
                    <div class="col-6">Requested On</div>
                    <div class="col-6"><span class="pr-3"> : </span>{{details.created_at}}</div>
                </div>
            </modal>
            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                <p>You are about to delete the request <b v-html="deletingItem && deletingItem.name"></b>. Are you sure ?
                </p>

                <template #loading>
                    <loading-animation/>
                    Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                </template>
            </delete-modal>
        </template>

    </vue-table-card>
</template>

<script>

import urls from '../../../../data/urls';

export default {
    name: 'TrainingRequest',
    data () {
        return {
            listUrl: urls.admin.trainingRequest.list,
            deleteUrl: urls.admin.trainingRequest.delete,
            details: '',
            deletingItem: null,
            fields: [
                {
                    name: 'training_program',
                    sortField: 'training_program',
                    title: 'Training Program'
                },
                {
                    name: 'training_type',
                    sortField: 'training_type',
                    title: 'Type'
                },
                {
                    name: '__slot:name',
                    sortField: 'name',
                    title: 'Name'
                },
                {
                    name: 'phone_number',
                    sortField: 'phone_number',
                    title: 'Phone'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ]
        };
    },
    methods: {
        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },
        viewDetails (item) {
            this.details = { ...item };
            this.$refs.detailsModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        }
    }
};
</script>
