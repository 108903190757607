<template>
    <div v-if="item" class="px-lg-4 text-center-res py-4">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="square-before">
                    <img :src="item.image" class="img-fluid square-before" alt="">
                </div>
            </div>
            <div class="col-12 col-md-6 pos-r">
                <h2 class="fs-7 font-jost-semi mb-3 mt-4 mt-lg-5">{{ item.heading }}</h2>
                <p class="font-lato-medium fs-lg-1 lh-17">{{ item.content }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurFacilitiesItem',
    props: {
        item: { type: Object, default: null }
    }
};
</script>

<style scoped lang="scss">
.square-before {
    position: relative;
    z-index: 5 !important;

    &::before {
        content: '';
        position: absolute;
        top: -6%;
        left: 50%;
        transform: translateX(-50%);
        width: 88%;
        height: 110% !important;
        border: 1px solid var(--color-primary);
    }
}
</style>
