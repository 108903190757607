<template>
    <div class="card-one">
        <div class="row">

            <div class="col-12 col-lg-7">

                <div class="d-lg-none">
                    <!--                    <section-heading :title="details.heading" bottom-tag-align="left"-->
                    <!--                                     class="text-left mt-4 font-lato-regular"-->
                    <!--                                     align="left">-->
                    <!--                    </section-heading>-->
                    <section-heading title="ABOUT US" bottom-tag-align="left"
                                     class="font-lato-regular mt-4 "
                                     align="left">
                    </section-heading>

                    <!--                    <p class="fs-3  d-sm-none font-lato-medium mb-4">{{ details.description1 }}</p>-->
                    <!--                                        <p v-if="!details.description1" class="fs-5  lh-1 left-align font-jost-semi-bold">-->
                    <!--                                            Limitless Learning and get more possibilities</p>-->
                    <p v-if="details.heading" class="fs-7 text-center-res line-h2  font-jost-semi-bold">
                        {{ details.heading }}</p>
                    <p v-else class="fs-7 text-center-res line-h2  font-jost-semi-bold">
                        Limitless Learning and get more possibilities</p>
                </div>

                <div class="parent">
                    <img class="image1 pos-r" :src="details.image1" alt="">
                    <img class="image2" :src="details.image2" alt="">
                </div>
            </div>
            <div class="col-12 col-lg-5 t-center">
                <p class="sec-1 mb-0 ">
                    <section-heading title="ABOUT US" bottom-tag-align="left"
                                     class="font-lato-regular d-lg-content margin-tp-md-6 "
                                     align="left">
                    </section-heading>

                    <!--                                        <section-heading :title="details.heading" bottom-tag-align="left"-->
                    <!--                                                         class="font-lato-regular d-lg-content "-->
                    <!--                                                         align="left" >-->
                    <!--                                        </section-heading>-->
                <p v-if="details.heading" class="fs-7 mt-2 text-black-1 d-sm-none line-h2 left-align font-jost-medium">
                    {{ details.heading }}</p>
                <p v-else class="fs-7 text-black-1 mt-2 d-sm-none line-h2 left-align font-jost-medium">
                    Limitless Learning And Get<br> More Possibilities</p>
                <p class="fs-1 d-lg-content  left-align font-lato-medium">{{ details.description1 }}</p>

                <p class="fs-1 d-lg-none pl-2 pr-2 pt-0 pb-0 font-lato-medium mb-4 ">
                    {{ details.description1 }}</p>
                <p class="fs-1  d-sm-none font-lato-medium align-center">
                    {{ details.description2 }}
                </p>

                <img alt="Signature Image" class="sign mt-lg-2 mt-5 sm-center" :src="details.signature">
                <!--                <img src="../../assets/web/about-us/signs.png" v-if="!details.signature">-->
                <p class="font-lato-semi-bold mb-1 fs-2 fs-lg-1 sm-center">{{ details.name }}</p>
                <p class="font-lato-regular fs-1 sm-center">{{ details.role }}</p>

            </div>
        </div>
    </div>

</template>
<script>
import SectionHeading from '@components/ui/SectionHeading';

export default {
    name: 'AboutusCardOne',
    props: {
        details: { type: Object, default: null }
    },
    components: { SectionHeading }
};
</script>
<style scoped lang="scss">

.sec-1 {
    padding: 4px;
    align-items: center;
}

.line-h2 {
    line-height: 2.3rem;
}

.center {
    text-align: center !important;
}

.parent {
    position: relative;
    top: 0;
    left: 0;
    margin: 0 -30px !important;
    @media (min-width: 992px) {
        margin: 0 !important;
    }
}

.image1 {
    position: relative;
    top: 12px;
    left: 13px;
    width: 63%;
    height: 323px;
    z-index: 1;
}

.image2 {
    position: absolute;
    top: 152px;
    width: 30%;
    right: 83px;
    height: 234px;
}

.sign {
    width: 90px;
    //height:100px;
}

.card-one {
    padding-top: 3rem;

    padding-right: 1rem;

}

.d-lg-none {
    display: none;
}
.t-center{
        text-align:left!important;
    }

@media (max-width: 750px) {
    .sec-1 {
        padding: 1rem;
        align-items: center;
        margin-top: 10px;
        text-align: center;

    }
    .t-center{
        text-align:center!important;
    }
    .sign {
        width: 80px;
        margin-left: 40%;
        margin-right: 50%;
    }

    .image2 {
        display: none;
    }

    .image1 {
        width: 100%;
        background-position: center 100%;
        padding: 0;
        left: -6px;
        background-size: contain;
    }
    .sm-center {
        text-align: center;
    }

    .card-one {
        padding-top: 0px;
    }

    .d-lg-none {
        display: contents;

    }

    .d-lg-content {
        display: none;
    }

}

@media (min-width: 768px) and (max-width: 1024px) {
    .margin-tp-md-6 {
        margin-top: 6rem !important;
    }
    .image2 {
    position: absolute;
    top: 152px;
    width: 30%;
    right: 100px;
    height: 234px;
}
}
</style>
