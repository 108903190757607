<template>
    <div class="of-h">
        <data-loading-component v-if="dataLoading"/>
        <div v-else>
            <div v-if="details">
                <training-center-banner :details="details.banners"></training-center-banner>
                <div v-if="details.training_centers.length!==0">
                    <template v-for="(item, index) in details.training_centers">
                        <div :id="'item' + item.id" :key="index">
                            <training-center01 @viewLocation="viewLocation" v-if="getCard(index)"
                                               :details="item"></training-center01>
                            <training-center02 @viewLocation="viewLocation" v-else
                                               :details="item"></training-center02>
                        </div>
                    </template>
                </div>
                <div v-else class="text-center p-5">
                    <p>No training centers available currently.</p>
                </div>
                <div class="">
                    <training-center-map v-if="details.training_centers.length!==0" class=""
                                         :details="details.training_centers"></training-center-map>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TrainingCenterBanner from '../components/training-center/TrainingCenterBanner';
import TrainingCenter01 from '../components/training-center/TrainingCenter01';
import TrainingCenter02 from '../components/training-center/TrainingCenter02';
import TrainingCenterMap from '../components/training-center/TrainingCenterMap';
import axios from 'secure-axios';
import urls from '../data/urls';
import DataLoadingComponent from '../components/ui/DataLoadingComponent';

export default {
    name: 'TrainingCentersPage',
    components: {
        DataLoadingComponent,
        TrainingCenterMap,
        TrainingCenter02,
        TrainingCenterBanner,
        TrainingCenter01
    },

    data () {
        return {
            details: '',
            dataLoading: false
        };
    },

    beforeMount () {
        this.loadDetails();
    },

    methods: {
        viewLocation (i) {
            window.open('https://maps.google.com/maps?q=' + i.latitude + ',' + i.longitude + '&hl=es;z=14&amp;output=embed');
        },
        getCard (index) {
            return index % 2 === 0;
        },
        async loadDetails () {
            this.dataLoading = true;
            const response = await axios.get(urls.website.trainingCenter);
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
                if (this.$route.hash) {
                    this.goTo();
                }
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
            this.$emit('loadingSuccess');
        },
        goTo () {
            window.location.href = this.$route.hash;
        }
    }
};
</script>

<style scoped>

</style>
