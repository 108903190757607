<template>
    <div class="bg-3">
        <div class="container py-5">
            <div class="row">
                <div class=" col-lg-12 col-12 row mb-5 mt-4">

                    <div class="col-lg-4">
                        <validated-ajax-vue-select size="md" border-radius="0" class="mb-3 c-input-select-7"
                                                   :url="trainingCenterOptions" v-model="training_center"
                                                   placeholder="Select Training Center"/>
                    </div>
                    <div class="col-lg-8">
                        <validated-input v-model="search" placeholder="Enter keyword here"
                                         border-radius="0" class="gh font-lato-medium" size="sm">
                            <template #append>
                                <btn size="sm" border-radius="0" color="primary green-c" text="SEARCH COURSE"
                                     @click="filterSearch"/>
                            </template>
                        </validated-input>

                    </div>
                </div>
                <div class="col-12">
                    <p class="font-jost-semi-bold fs-7" align="center"> Veterinary Training Programs</p>
                </div>
            </div>
            <div class="row " v-if="details.length !== 0">
                <div v-for="(i, index) in details" :key="index"
                     class="col-12 col-md-6 col-lg-4 d-lg-block d-md-none d-sm-none mb-4">
                    <course-card :img="i.course_image" :details="i"
                                 :training_center="training_center_i"></course-card>
                </div>

                <div class="col-12">
                    <our-courses-swiper :details="details" class="d-lg-none"></our-courses-swiper>
                </div>
                <div v-if="details.length >= 6" class="col-12 text-center">
                    <btn @click="viewAll" text="LOAD MORE" border-radius="0"
                         class="c-btn-2 mr-3 mb-lg-0 mt-lg-5 mt-3"></btn>
                </div>
            </div>
            <div v-else class="text-center">
                <h5>No courses available currently.</h5>
            </div>
        </div>
    </div>
</template>

<script>
import CourseCard from '../../components/home/CourseCard';
import urls from '../../data/urls';
import OurCoursesSwiper from '../../components/home/OurCoursesSwiper';

export default {
    name: 'OurCourse',
    props: {
        details: { type: Array, default: null },
        training_center_i: { type: String, default: '' },
        search_i: { type: String, default: '' }
    },
    components: { CourseCard, OurCoursesSwiper },
    data () {
        const that = this;
        return {
            training_center: that.training_center_i,
            search: that.search_i,
            trainingCenterOptions: urls.admin.trainingCenter.vueSelect
        };
    },
    watch: {
        training_center: {
            handler () {
                this.filterTrainingCenter();
            }
        }
    },
    methods: {
        filterTrainingCenter () {
            this.$emit('filterTrainingCenter', this.training_center);
        },
        filterSearch () {
            this.$emit('filterSearch', this.search);
        },
        viewAll () {
            this.$emit('viewAll');
        }
    }
};
</script>

<style lang="scss" scoped>
.green-c {
    background-color: #3f9b59;
    color: white;

    height: 104%;
    position: absolute;
    z-index: 10 !important;
    right: 0;
}

.gh {
    height: 1.9rem !important;
}

.form-control > .control:not(:last-child) {
    padding-right: var(--form-control-padding-x);
    height: 1.9rem;
}
</style>
