<template>
    <div v-if="details">
        <div id="top-banner">
            <base-banner :img="details.banners" height="half"></base-banner>
        </div>
        <div class="container py-5">
            <div class="row">
                <div class="col-12">
                    <div @click="goBack">
                        <img src="../../assets/web/course-offered/greeen-arrow.png" class="icon-md-1" alt="">
                    </div>
                    <h2 class="font-jost-semi fs-lg-8 mb-4 mt-3 w-lg-34re">
                        {{ details.course.name }}
                    </h2>
                </div>
                <div class="col-12 col-lg-8">
                    <!--Tabs-->
                    <tabs class="c-tabs-light">
                        <tab title="Overview">
                            <div class="w-85p">
                                <div class="container mb-5" v-for="(i, index) in details.overview" :key="index">
                                    <div class="mb-3">
                                        <h2 v-if="i.type" class="font-lato-bold fs-lg-1 mb-4">
                                            <span v-if="i.title">{{ i.title }}</span>
                                            <span v-else>Title</span>
                                        </h2>
                                        <div v-else>
                                            <img v-if="i.image" :src="i.image" class="img-fluid" alt="">
                                            <img v-else src="../../assets/web/course-offered/bgi.jpg" class="img-fluid"
                                                 alt="">
                                        </div>
                                    </div>
                                    <short-section>
                                        <template #content>
                                            <div v-html="i.description"></div>
                                        </template>
                                    </short-section>
                                </div>
                                <h2 class="font-jost-semi fs-lg-1 mb-4">TRAINING EXPENSES PER HEAD</h2>
                                <custom-simple-table :fields="incidentFields" :show-border="true"
                                                     class="c-simple-table c-b-1 font-lato-medium w-90p"
                                                     :data="details.course.charges"
                                                     :tax_percentage="details.course.tax_percentage"
                                                     :total="getTotal(details.course.charges)"></custom-simple-table>
                            </div>
                        </tab>
                        <tab title="Syllabus" class="font-lato-medium">
                            <div>
                                <Syllabus :details="details.syllabus"></Syllabus>
                            </div>
                        </tab>
                        <tab title="Faculty" class="font-lato-medium">
                            <div>
                                <Faculty :details="details.faculty"></Faculty>
                            </div>
                        </tab>

                        <tab title="Faq" class="font-lato-medium">
                            <div>
                                <Faq :details="details.faq"></Faq>
                            </div>
                        </tab>
                    </tabs>
                </div>
                <div class="col-12 col-lg-4 pos-r">
                    <course-info-card :id="id" :details="details" @notLoggedIn="$refs.NotLoggedInModal.show()"
                                      class="pos-a-left-ol"></course-info-card>
                </div>
            </div>
        </div>
        <div v-if="details.related_courses.length!==0" id="related-training" class="bg-5 py-5">
            <div class="container">
                <div class="px-lg-6">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="font-jost-semi text-center fs-lg-6 c-mb-4 mt-3">
                                Related Training Programs
                            </h3>
                        </div>
                        <div v-for="(i, index) in details.related_courses" :key="index"
                             class="col-12 col-md-6 col-lg-4 d-sm-none">
                            <course-card :img="i.course_image"
                                         :details="i"></course-card>
                        </div>
                        <div class="col-12">
                            <courses-swiper :details="details.related_courses" class="d-lg-none"></courses-swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modal class="c-modal" :no-close-on-backdrop="false" title=""
               ref="NotLoggedInModal" width="25r" header-color="">
            <div class="text-center px-lg-5 pb-5 pt-4">
                <div class="fl-x fl-j-c mb-4">
                    <div class="custom-info-icon"
                         style="">
                        i
                    </div>
                </div>

                <p class="font-lato-semi-bold fs-lg-4 mb-4">You're not logged in !</p>
                <p class="fs--1 mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo, natus! Lorem
                    ipsum
                    dolor sit.</p>
                <router-link to="/login/">
                    <btn text="LOGIN NOW" border-radius="0" class="px-5"></btn>
                </router-link>
            </div>
        </modal>

    </div>
</template>

<script>
import BaseBanner from '@components/ui/BaseBanner';
import CourseInfoCard from '../../views/training-programs/CourseInfoCard';
import CourseCard from '../../components/home/CourseCard';
import CoursesSwiper from '../../components/courses/CoursesSwiper';
import Syllabus from '../../views/training-programs/Syllabus';
import Faculty from '@/views/training-programs/Faculty';
import Faq from '@/views/training-programs/Faq';
import axios from 'secure-axios';
import urls from '../../data/urls';
import ShortSection from '@components/ui/ShortSection';
import CustomSimpleTable from '../../components/lego-custom/custom-simple-table/CustomSimpleTable';

export default {
    name: 'CourseInformation',
    components: {
        CoursesSwiper,
        CustomSimpleTable,
        ShortSection,
        Syllabus,
        CourseCard,
        CourseInfoCard,
        BaseBanner,
        Faculty,
        Faq
    },
    data () {
        return {
            incidentFields: [
                {
                    name: 'charge_type',
                    title: 'Particulars'
                },
                {
                    name: 'days',
                    title: 'Days'
                },
                {
                    name: 'rate',
                    title: 'Rate'
                },
                {
                    name: 'rate_per_person',
                    title: 'Rate/Person'
                }
            ],
            loading: false,
            details: '',
            id: this.$route.params.id
        };
    },
    mounted () {
        this.loadDetails();
    },
    watch: {
        id: {
            handler () {
                this.loadDetails();
            }
        }
    },
    methods: {
        goBack () {
            if (this.$route.hash) {
                this.$router.push('/course-offered/' + this.$route.hash);
            } else {
                this.$router.push('/course-offered/');
            }
        },
        getTotal (charges) {
            let amount = 0;
            for (let i = 0; i < charges.length; i++) {
                amount += charges[i].rate_per_person;
            }
            return amount;
        },
        async loadDetails () {
            this.loading = true;
            const response = await axios.form(urls.website.course.coursesOfferedDetails, { id: this.id });
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                if (json.message) {
                    this.$notify(json.message, 'Error', {
                        type: 'danger'
                    });
                    this.$router.push('/course-offered/');
                } else {
                    this.$notify('Could not load data, Please try again later', 'Error', {
                        type: 'danger'
                    });
                }
            }
            this.loading = false;
        }
    }
};
</script>

<style scoped lang="scss">
.pos-a-left-ol {
    @media (min-width: 992px) {
        position: absolute;
        width: 70%;
        //left: -4rem;
        //left: -9rem;
        left: -13rem;
        top: -3rem;
    }

}

@media (min-width: 768px) and (max-width: 1024px) {
    .c-tabs-light {
        min-height: 0vh !important;
    }
}
</style>
