<template>
    <div class="row fl-x-cc mt-4">
        <div class="col-12 col-lg-11 mb-5">
            <s-form class="">
                <div class="fl-x-cc im mb-4 mt-4">
                    <img src="../../assets/web/about-us/tick.png">
                </div>
                <p class="font-lato-bold fs-3 mb-3 fl-x-cc text-black-1">Request Submitted!</p>
                <p class="font-lato-semi-bold fs-lg--2 fl-x-cc p-0 mb-2 text-black-1" align="center">The request have
                    been successfully
                    submitted, Kindly wait for the confirmation.</p>

                <div class="mt-6 mb-4 fl-x-cc">
                    <btn type="button" @click="closeModal" border-radius="0" text="Close" class="px-5 py-2"
                         design="basic-b"></btn>
                </div>
            </s-form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Request-Training-Submited',
    methods: {
        closeModal () {
            this.$emit('close');
        }
    }
};
</script>

<style scoped>
.im img {
    height: 90px;
    width: 90px;
}
</style>
