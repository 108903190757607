<template>
<div>
    <div class="container py-7">
        <h5>Training Programs</h5>
        <course-item></course-item>
    </div>
</div>
</template>

<script>
import CourseItem from '../components/training-programs/CourseItem';
export default {
    name: 'MyTrainingPrograms',
    components: { CourseItem }
};
</script>

<style scoped>

</style>
