<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" :save-params="{course}">
        <div class="row">
            <div class="col-12">
                <validated-vue-select :options="yearOptions" label="Financial Year" v-model="model.financial_year" class="required-field"
                                      :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <file-input class="c-file-input" ref="file" label="Image" v-model="model.image_file"
                            :disabled="loading" name="Image File">
                </file-input>
                <image-info :width="835" :height="820"/>
            </div>
            <div class="col-12">
                <validated-text-area label="Description" v-model="model.description" class="required-field"
                                     :rules="{required: true, max: 500}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input tytpe="number" label="Order" v-model="model.order"
                                 :rules="{required: false, min_value:1}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                    :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn class="trn" text="Save" loading-text="Saving..." size="sm" :disabled="loading" :loading="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>

import urls from '../../../../data/urls';

function generateArrayOfYears () {
    const date = new Date().getFullYear();
    const min = date - 5;
    const years = [];

    for (let i = min; i <= (date + 5); i++) {
        const data = { label: i, value: i };
        years.push(data);
    }
    return years.reverse();
}

export default {
    props: {
        course: { type: String }
    },
    name: 'AddTrainingSchedule',

    data () {
        const Options = generateArrayOfYears();
        return {
            yearOptions: Options,
            year: new Date().getFullYear(),
            addUrl: urls.admin.course.detailsUrl.trainingSchedule.addEdit
        };
    },

    methods: {
        formSuccess () {
            this.$notify('Successfully Added Item..!', 'Success',
                {
                    type: 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
