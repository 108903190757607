var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card bs-4"},[_c('div',{staticClass:"fl-te-c mb-3"},[_c('h4',[_vm._v("NEWS IMAGES")]),_c('btn',{attrs:{"color":"success","icon":"fa fa-plus","size":"sm","text":"Add"},on:{"click":function($event){return _vm.$refs.addModal.show()}}})],1),_c('simple-table',{ref:"table",attrs:{"data":_vm.details,"fields":_vm.fields},scopedSlots:_vm._u([{key:"image",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"container-image",on:{"click":function($event){return _vm.viewFile(rowData.image)}}},[_c('img',{staticClass:"c-image",attrs:{"src":rowData.image,"alt":"Avatar"}}),_c('div',{staticClass:"c-overlay"},[_c('div',{staticClass:"c-icon"},[_c('i',{staticClass:"fa fa-eye"})])])])]}},{key:"is_thumbnail",fn:function(ref){
var rowData = ref.rowData;
return [(rowData.is_thumbnail)?_c('p',[_vm._v("Yes")]):_c('p',[_vm._v("No")])]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"btn-group-tight"},[_c('edit-btn',{on:{"click":function($event){return _vm.setEdit(rowData)}}}),_c('delete-btn',{on:{"click":function($event){return _vm.setDelete(rowData)}}})],1)]}}])}),_c('modal',{ref:"addModal",attrs:{"title":"Add Image","width":"30r","header-color":"primary","no-close-on-backdrop":""}},[_c('b-form',{attrs:{"save-url":_vm.addUrl,"save-params":{news: _vm.news}},on:{"success":_vm.formSuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('file-input',{staticClass:"c-file-input",attrs:{"label":"Image","disabled":loading},model:{value:(model.image),callback:function ($$v) {_vm.$set(model, "image", $$v)},expression:"model.image"}}),_c('image-info',{attrs:{"height":440,"width":885}})],1),_c('div',{staticClass:"col-12"},[_c('validated-checkbox',{attrs:{"label":"Is Thumbnail","disabled":loading},model:{value:(model.is_thumbnail),callback:function ($$v) {_vm.$set(model, "is_thumbnail", $$v)},expression:"model.is_thumbnail"}})],1),_c('div',{staticClass:"col-12 text-right"},[_c('btn',{staticClass:"trn",attrs:{"text":"Save","loading-text":"Saving...","size":"sm","disabled":loading,"loading":loading}})],1)])]}}])})],1),_c('modal',{ref:"editModal",attrs:{"title":"Edit Image","width":"30r","header-color":"primary","no-close-on-backdrop":""}},[_c('b-form',{attrs:{"save-url":_vm.addUrl,"save-params":{news: _vm.news},"initial-data":_vm.editingItem},on:{"success":_vm.formSuccess1},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('file-input',{ref:"file",staticClass:"c-file-input",attrs:{"label":"Image","disabled":loading},on:{"input":_vm.clearFile},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"btn-group"},[(_vm.editingItem.image)?_c('btn',{staticClass:"fa fa-eye",attrs:{"type":"button","size":"xs"},on:{"click":function($event){return _vm.viewFile(_vm.editingItem.image)}}}):_vm._e()],1)]},proxy:true}],null,true),model:{value:(model.image),callback:function ($$v) {_vm.$set(model, "image", $$v)},expression:"model.image"}}),_c('image-info',{attrs:{"height":440,"width":885}})],1),_c('div',{staticClass:"col-12"},[_c('validated-checkbox',{attrs:{"label":"Is Thumbnail","disabled":loading},model:{value:(model.is_thumbnail),callback:function ($$v) {_vm.$set(model, "is_thumbnail", $$v)},expression:"model.is_thumbnail"}})],1),_c('div',{staticClass:"col-12 text-right"},[_c('btn',{staticClass:"trn",attrs:{"text":"Update","loading-text":"Updating...","size":"sm","disabled":loading,"loading":loading}})],1)])]}}])})],1),_c('delete-modal',{ref:"deleteModal",attrs:{"url":_vm.deleteUrl,"params":_vm.deletingItem},on:{"response":_vm.deleteComplete},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading-animation'),_vm._v(" Please wait while we delete "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.deletingItem && _vm.deletingItem.name)}}),_vm._v(". ")]},proxy:true}])},[_c('p',[_vm._v("You are about to delete the image "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.deletingItem && _vm.deletingItem.name)}}),_vm._v(". Are you sure ?")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }