<template>
    <thead>
    <tr>
        <th v-for="(field, fieldIndex) in fields"
            @click="orderBy(field, $event)"
            :key="fieldIndex"
            :id="'_' + field.name"
            :style="{width: field.width}"
            :class="[field.titleClass, sortClass(field),  {'sortable': isSortable(field)}]"
            v-html="renderTitle(field)"
        >
        </th>
    </tr>
    </thead>
</template>

<script>
export default {
    props: {
        fields: {
            type: Array,
            required: true,
            default () {
                return [];
            }
        },

        sortOrder: {
            type: Array,
            default () {
                return [];
            }
        },

        multiSortKey: {
            type: String,
            default: 'shift'
        },

        showSortIcons: {
            type: Boolean,
            default: true
        }

    },
    data () {
        return {
            css: {}
        };
    },
    methods: {
        isSortable (field) {
            return !(typeof field.sortField === 'undefined');
        },

        isInCurrentSortGroup (field) {
            return this.currentSortOrderPosition(field) !== false;
        },

        hasSortableIcon (field) {
            return this.isSortable(field) && this.css.sortableIcon !== '';
        },

        getTitle (field) {
            if (typeof (field.title) === 'function') return field.title();
            return typeof (field.title) === 'undefined' ? field.name.replace('.', ' ') : field.title;
        },

        renderTitle (field) {
            const title = this.getTitle(field);

            if (title.length > 0 && (this.isInCurrentSortGroup(field) || this.hasSortableIcon(field))) {
                const style = `opacity:${this.sortIconOpacity(field)};position:relative;float:right`;
                const iconTag = this.showSortIcons ? this.renderIconTag(['sort-icon', this.sortIcon(field)], `style="${style}"`) : '';
                return title + ' ' + iconTag;
            }

            return title;
        },

        renderIconTag (classes, options = '') {
            return `<i class="${classes.join(' ')}" ${options}></i>`;
        },

        fieldIsInSortOrderPosition (field, i) {
            return this.sortOrder[i].field === field.name && this.sortOrder[i].sortField === field.sortField;
        },

        currentSortOrderPosition (field) {
            if (!this.isSortable(field)) {
                return false;
            }

            for (let i = 0; i < this.sortOrder.length; i++) {
                if (this.fieldIsInSortOrderPosition(field, i)) {
                    return i;
                }
            }

            return false;
        },

        sortIconOpacity (field) {
            /*
             * fields with stronger precedence have darker color
             *
             * if there are few fields, we go down by 0.3
             * ex. 2 fields are selected: 1.0, 0.7
             *
             * if there are more we go down evenly on the given spectrum
             * ex. 6 fields are selected: 1.0, 0.86, 0.72, 0.58, 0.44, 0.3
             */
            const max = 1.0;
            const min = 0.3;
            let step = 0.3;

            const count = this.sortOrder.length;
            const current = this.currentSortOrderPosition(field);

            if (max - count * step < min) {
                step = (max - min) / (count - 1);
            }

            return max - current * step;
        },

        multiColumnSort (field) {
            const i = this.currentSortOrderPosition(field);

            if (i === false) { // this field is not in the sort array yet
                this.sortOrder.push({
                    field: field.name,
                    sortField: field.sortField,
                    direction: 'asc'
                });
            } else { // this field is in the sort array, now we change its state
                if (this.sortOrder[i].direction === 'asc') {
                    // switch direction
                    this.sortOrder[i].direction = 'desc';
                } else {
                    // remove sort condition
                    this.sortOrder.splice(i, 1);
                }
            }
        },

        singleColumnSort (field) {
            if (this.sortOrder.length === 0) {
                this.clearSortOrder();
            }

            this.sortOrder.splice(1); // removes additional columns

            if (this.fieldIsInSortOrderPosition(field, 0)) {
                // change sort direction
                this.sortOrder[0].direction = this.sortOrder[0].direction === 'asc' ? 'desc' : 'asc';
            } else {
                // reset sort direction
                this.sortOrder[0].direction = 'asc';
            }
            this.sortOrder[0].field = field.name;
            this.sortOrder[0].sortField = field.sortField;
        },

        clearSortOrder () {
            this.sortOrder.push({
                field: '',
                sortField: '',
                direction: 'asc'
            });
        },

        sortClass (field) {
            let cls = '';
            const i = this.currentSortOrderPosition(field);

            if (i !== false) {
                cls = (this.sortOrder[i].direction === 'asc') ? this.css.ascendingClass : this.css.descendingClass;
            }

            return cls;
        },

        sortIcon (field) {
            let cls = this.css.sortableIcon;
            const i = this.currentSortOrderPosition(field);

            if (i !== false) {
                cls = (this.sortOrder[i].direction === 'asc') ? this.css.ascendingIcon : this.css.descendingIcon;
            }

            return cls;
        },

        orderBy (field, event) {
            if (!this.isSortable(field)) return;

            const key = this.multiSortKey.toLowerCase() + 'Key';

            if (this.multiSort && event[key]) { // adding column to multisort
                this.multiColumnSort(field);
            } else {
                // no multisort, or resetting sort
                this.singleColumnSort(field);
            }

            this.$emit('sort-changed', this.sortOrder);
        }
    }
};
</script>
