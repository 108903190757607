<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-12 p-0">
                    <div class="col-lg-11 p-0">
                        <p class="font-lato-medium fs-lg-1 pr-lg-7 text-justify">Lorem ipsum dolor sit amet, consectetur adipisicing elit. A atque blanditiis
                            deserunt eaque eius, eligendi eum illum itaque iusto labore
                            laudantium  ut veniam vitae voluptas. Lorem ipsum dolor sit amet,
                            consectlor impedit ipsa?</p>
                    </div>
                </div>
                <div class="col-lg-11 im-2 p-0">
                    <ul class="custom-ul-list mt-3 fs-lg-1">
                        <li class="pl-3" v-for="(i, index) in details" :key="index">
                            <p class="font-jost-semi-bold fs-lg-1">{{ i.topic }}</p>
                            <p v-if="i.theory" class="ml-4 mt-3 "><span
                                class="font-jost-semi-bold fs-lg-1">Theory&nbsp;:&nbsp;</span>{{ i.theory }} hrs</p>
                            <p v-if="i.practical" class="ml-4 "><span
                                class="font-jost-semi-bold fs-lg-1">Practical&nbsp;:&nbsp;</span>{{ i.practical }}&nbsp;hrs</p>
                        </li>
                    </ul>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import bannerImg from '../../assets/web/course-offered/bgi.jpg';
import img1 from '../../assets/web/landing/our-courses/course-1.jpg';
import img2 from '../../assets/web/landing/our-courses/course-2.jpg';
import img3 from '../../assets/web/landing/our-courses/course-3.jpg';

export default {
    name: 'syllabus',
    props: {
        details: {
            type: Array, default: null
        }
    },
    components: {},
    data () {
        return {
            bannerImg: bannerImg,
            img1: img1,
            img2: img2,
            img3: img3,
            incidentFields: [
                {
                    name: 'particulars',
                    title: 'Particulars'
                },
                {
                    name: 'days',
                    title: 'Days'
                },
                {
                    name: 'rate',
                    title: 'Rate/Unit'
                },
                {
                    name: 'rate1',
                    title: 'Rate/Unit'
                }
            ],
            dataItems: [
                {
                    particulars: 'Lodging Charges',
                    days: 10,
                    rate: 'Rs. 100/day/person',
                    rate1: '1000'
                },
                {
                    particulars: 'Boarding Charges',
                    days: 10,
                    rate: 'Rs. 500/day/person',
                    rate1: '5000'
                },
                {
                    particulars: 'Tuition Fee',
                    days: 10,
                    rate: 'Rs. 25000/person',
                    rate1: '25000'
                }
            ]
        };
    }
};
</script>

<style scoped lang="scss">
.im-2 img {
    height: 150px;
    width: 150px;
}

.custom-ul-list {
    list-style: none;

    li {
        position: relative;

        &:before {
            content: "";
            width: 5px;
            height: 5px;
            background-color: var(--color-primary);
            border-radius: 50%;
            //transform: translateY(-40%);
            top: 9px;
            left: 0;
            position: absolute;
        }

        &:after {
            content: "";
            position: absolute;
            left: 2px;
            top: 17px;
            width: 1px;
            height: 100%;
            background-color: rgba(173, 189, 183, 0.5);
        }
    }
}

.pos-a-left-ol {
    @media (min-width: 992px) {
        position: absolute;
        width: 70%;
        left: -4rem;
        top: -5rem;
    }

}
</style>
