<template>
    <b-form ref="addForm" :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
        <div class="row">
            <div class="col-12">
                <validated-input label="Name" v-model="model.name" class="required-field"
                                 :rules="{required: true, max:30}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-input label="Location" v-model="model.location" class="required-field"
                                 :rules="{required: true, max: 50}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-ajax-vue-select name="State" :url="stateOptions" class="required-field"
                                           @input="loadDistrictOptions" :rules="rules.state" label="State"
                                           v-model="model.state"/>
            </div>
            <div class="col-6">
                <validated-vue-select name="District" :options="districtOptions" class="required-field"
                                      :rules="rules.district" label="District" @input="checkIfNullValue"
                                      v-model="model.district"/>
            </div>
            <div class="col-6">
                <validated-input type="number" label="Pincode" v-model="model.pincode" class="required-field"
                                 :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input type="number" label="Phone" v-model="model.phone" class="required-field"
                                 :rules="{required: true, max: 10, min: 10}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-input type="email" label="E-Mail" v-model="model.email" class="required-field"
                                 :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input type="number" label="Open Days" v-model="model.opening_days" class="required-field"
                                 :rules="{required: true, min_value:1, max_value:7}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input type="number" label="Open Hours" v-model="model.opening_hours" class="required-field"
                                 :rules="{required: true, min_value:1, max_value:24}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-text-area label="Content 1" v-model="model.content1" class="required-field"
                                     :rules="{required: true, max:250}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-text-area label="Content 2" v-model="model.content2" class="required-field"
                                     :rules="{required: true, max:250}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input label="Latitude" v-model="model.latitude" class="required-field"
                                 :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input label="Longitude" v-model="model.longitude" class="required-field"
                                 :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input type="number" label="Order" v-model="model.order"
                                 :rules="{required: false, min_value:1}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                    :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn class="trn" text="Save" loading-text="Saving..." size="sm" :disabled="loading" :loading="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>
import urls from '../../../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'AddTrainingCenter',

    data () {
        return {
            addUrl: urls.admin.trainingCenter.addEdit,
            districtOptions: [],
            stateOptions: urls.masterData.State.stateOptions,
            rules: {
                state: {
                    required: true
                },
                district: {
                    required: true
                }
            }
        };
    },

    methods: {
        loadDistrictOptions (item) {
            if (this.$refs.addForm.getModelValue('state') === null) {
                this.$refs.addForm.setModelValue('state', '');
            }
            this.districtOptions = [];
            this.$refs.addForm.setModelValue('district', '');
            const that = this;
            axios.form(urls.masterData.District.districtOptions, { state: item }).then(function (response) {
                const json = response.data;
                that.districtOptions = json.data;
            });
        },
        checkIfNullValue (item) {
            if (this.$refs.addForm.getModelValue('district') === null) {
                this.$refs.addForm.setModelValue('district', '');
            }
        },
        formSuccess () {
            this.$notify('Successfully Added Training Center..!', 'Success',
                {
                    type: 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
