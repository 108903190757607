<template>
    <div class="section-bg pt-0 mt-0 p-0 text-white pos-r">
        <div class="overlay-dark"></div>
        <div class="container  c-section-py">
            <div class="row ">
                <div class="col-12">
                    <section-heading color="white" class="d-lg-none mb-41" title="WHY CHOOSE US"
                                     bottom-tag-align="center"
                                     align="left"></section-heading>
                </div>
                <div class="col-12 col-md-6 pos-r">
                    <video controls class="w-100 h-lg-20re pos-a-t pos-r">
                        <source :src="details.media" type="video/mp4">
                    </video>
                </div>
                <div class="col-12 col-md-6">
                    <div class="pt-4 mt-lg-5 pb-3 ml-lg-4 pr-lg-6">
                        <section-heading title="WHY CHOOSE US" class="d-sm-none" color="white"
                                         bottom-tag-align="left" align="left"></section-heading>
                        <p class="mb-41 mt-42 font-lato-medium fs-lg-1 lh-17 text-center-res">
                            {{ details.content }}
                        </p>
                        <div class="fl-x fl-a-c">
                            <div v-if="details.brochure" @click="viewFile(details.brochure)">
                                <btn text="DOWNLOAD BROCHURE" border-radius="0" class="c-btn-2 mr-3 mb-lg-0"></btn>
                            </div>
                            <div @click="viewFile(details.media)">
                                <btn-underline underline-color="white" :icon-img="playButtonImg"
                                               text="Watch Training Videos"></btn-underline>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SectionHeading from '@components/ui/SectionHeading';
import BtnUnderline from '@components/ui/BtnUnderline';
import playButtonImg from '../../assets/web/landing/play-white.png';

export default {
    name: 'AboutWhyChooseUs',
    props: {
        details: { type: Object }
    },
    components: { BtnUnderline, SectionHeading },
    data () {
        return {
            playButtonImg: playButtonImg
        };
    },
    methods: {
        viewFile (url) {
            window.open(url);
        }
    }
};
</script>

<style scoped lang="scss">
.underline-primary {
    position: relative;
    padding-bottom: var(--spacer-2);

    &::after {
        content: '';
        position: absolute;
        height: 1.7px;
        bottom: 0;
        background: var(--color-primary);
        width: 100%;
    }
}

.section-bg {
    //background: url("../../assets/web/about-us/bgi.jpg");
    background: url("../../assets/web/about-us/about-us-why-choose us.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 48% 0%;
    @media (min-width: 992px) {
        background-position: 0% 100%;
    }
}

.pos-a-t {
    position: relative;
    @media (min-width: 992px) {
        position: absolute;
        top: -10rem !important;
        left: -3.1rem;
    }
}

.h-140p {
    height: 140% !important;
}

.overlay-dark {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}

.h-lg-20re {
    @media (min-width: 992px) {
        height: 30rem !important;
    }
}

.pos-a-c-1 {
    position: absolute;
    top: 20%;
    left: 38%;
}
</style>
