<template>
    <div class="row w-100">
        <div class="col-md-6 mb-5">
            <state ref="state" @refresh="refreshDistrict" class="bs-4"/>
        </div>
        <div class="col-md-6 mb-5">
            <district ref="district" class="bs-4"/>
        </div>
        <div class="col-md-6 mb-5">
            <news-category class="bs-4"/>
        </div>
    </div>
</template>

<script>
import State from '../master-data/State';
import District from '../master-data/District';
import NewsCategory from './NewsCategory';

export default {
    name: 'MasterData',
    components: {
        NewsCategory,
        State,
        District
    },
    methods: {
        refreshDistrict () {
            this.$refs.district.refresh();
        }
    }
};
</script>
