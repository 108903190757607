<template>
    <div>
        <div class="card bs-4">
            <div class="fl-te-c">
                <h4 class="mb-3">Course Details</h4>
                <btn text="Back" @click="$router.push('/admin/courses/')" size="sm"/>
            </div>
            <edit-course v-if="details" :initial-data="details" @success="loadDetails"/>
            <p v-else class="text-center p-3">No data available.</p>
        </div>
        <course-overview :checkIfPublished="checkIfPublished"/>
        <course-charge @updateCourseFee="updateCourseFee" :checkIfPublished="checkIfPublished"/>
        <course-syllabus :checkIfPublished="checkIfPublished"/>
        <course-faculty :checkIfPublished="checkIfPublished" :location="details.training_location"/>
        <course-f-a-q :checkIfPublished="checkIfPublished"/>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../data/urls';
import EditCourse from './EditOurCourses';
import CourseCharge from './course-charge/CourseChargeListing';
import CourseSyllabus from './course-syllabus/CourseSyllabusListing';
import CourseOverview from './course-overview/CourseOverviewListing';
import CourseFAQ from './course-faq/CourseFAQListing';
import CourseFaculty from './course-faculty/CourseFacultyListing';

export default {
    name: 'DetailsPage',
    components: { CourseFaculty, CourseFAQ, CourseOverview, CourseSyllabus, CourseCharge, EditCourse },
    data () {
        return {
            loading: false,
            details: '',
            id: this.$route.params.id
        };
    },
    mounted () {
        this.loadDetails();
    },
    computed: {
        checkIfPublished () {
            return this.details.published === true;
        }
    },
    methods: {
        updateCourseFee (courseFee) {
            this.details.course_fee = courseFee;
        },
        async loadDetails () {
            this.loading = true;
            const response = await axios.form(urls.admin.course.details, { id: this.id });
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.loading = false;
        }
    }
};
</script>

<style scoped>

</style>
