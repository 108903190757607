<template>
    <div class="c-section-py">
        <div class="container">
            <section-heading title="WHY CHOOSE US"></section-heading>
            <div class="row">
                <div class="col-12">
                    <h2 class="font-jost-semi fs-lg-9 text-center mb-51 mt-3">Upgrade Your Skills And Upgrade Your
                        Life</h2>
                </div>
                <div class="col-12 col-md-6">
                    <video controls class="w-100 video-play-btn">
                        <source :src="details.media" type="video/mp4">
                    </video>
<!--                    <img src="../../assets/web/landing/play-button.png"-->
<!--                         class="z-index-4 cursor-pointer pointer-events-none pos-a-c icon-lg" alt="">-->

                </div>
                <div class="col-12 col-md-6">
                    <div class="pt-4 pb-3 ml-lg-4 pr-lg-6">
                        <p class="mb-5 font-lato-medium fs-lg-2 lh-17 text-center-res">
                            {{ details.content }}
                        </p>
                        <div class="fl-x fl-a-c">
                            <btn @click="$router.push('/about-us/')" text="DISCOVER MORE" border-radius="0"
                                 class="c-btn-2 mr-3 mb-lg-0"></btn>
                            <div @click="viewVideo(details.media)">
                                <btn-underline class="font-lato-semi-bold" :icon-img="playButtonImg" text="Watch Training Videos"></btn-underline>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SectionHeading from '@components/ui/SectionHeading';
import BtnUnderline from '@components/ui/BtnUnderline';
import playButtonImg from '../../assets/web/landing/playy.png';

export default {
    name: 'WhyChooseUs',
    props: {
        details: { type: Object, default: null }
    },
    components: { BtnUnderline, SectionHeading },
    data () {
        return {
            playButtonImg: playButtonImg
        };
    },
    methods: {
        viewVideo (url) {
            window.open(url);
        }
    }
};
</script>

<style scoped lang="scss">
.underline-primary {
    position: relative;
    padding-bottom: var(--spacer-2);

    &::after {
        content: '';
        position: absolute;
        height: 1.7px;
        bottom: 0;
        background: var(--color-primary);
        width: 100%;
    }
}

.banner-video {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 0;
}
</style>
