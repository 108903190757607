<template>
    <div>
        <div class="card">
            <div class="fl-te-c">
                <h4 class="mb-3">Training Center Details</h4>
                <btn text="Back" @click="$router.push('/admin/training-centers/')" size="sm"/>
            </div>
            <div v-if="loading" class="text-center py-5">
                <loading-animation/>
                Please wait while data is being loaded...
            </div>
            <edit-training-center ref="editForm" @success="loadDetails" v-if="details && !loading"
                                  :initial-data="details"/>
            <p v-if="!details && !loading" class="text-center p-3">No data available.</p>
        </div>
        <training-center-image v-if="details && !loading" @success="loadDetails" :training_center="id"
                               :details="details.center_images"/>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../../data/urls';
import EditTrainingCenter from './EditTrainingCenter';
import TrainingCenterImage from './TrainingCenterImageListing';

export default {
    name: 'DetailsPage',
    components: { TrainingCenterImage, EditTrainingCenter },
    data () {
        return {
            loading: false,
            details: '',
            id: this.$route.params.id
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            this.loading = true;
            const response = await axios.form(urls.admin.trainingCenter.details, { id: this.id });
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
                this.loading = false;
                if (this.details.state) {
                    const response = await axios.form(urls.masterData.District.districtOptions, { state: this.details.state });
                    const json = response.data;
                    this.$refs.editForm.districtOptions = json.data;
                }
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.loading = false;
        }
    }
}
;
</script>

<style scoped>

</style>
