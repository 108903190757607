<template>
    <b-form ref="form" :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
            :initial-data="initialData">
        <div class="row">
            <div class="col-12">
                <validated-input label="Heading" v-model="model.heading"
                                 :rules="{required: true, max:60}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-text-area label="Description" v-model="model.description"
                                    :rules="{required: true, max:300}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input label="Name" v-model="model.name"
                                 :rules="{required: true, max: 50}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input label="Role" v-model="model.role"
                                 :rules="{required: true, max: 50}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input type="number" label="Order" v-model="model.order"
                                 :rules="{required: false, min_value:1}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <btn class="trn" text="Update" loading-text="Updating..." size="sm" :disabled="loading"
                     :loading="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>

import urls from '../../../../data/urls';

export default {
    name: 'EditContactUsItem',

    props: { initialData: { type: Object } },

    data () {
        return {
            addUrl: urls.admin.contactUsPage.addEdit
        };
    },

    methods: {
        clearFile (file) {
            this.initialData.banner_image = '';
        },
        viewFile (file) {
            window.open(file);
        },
        formSuccess () {
            this.$notify('Successfully Updated Item..!', 'Success',
                {
                    type: 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
