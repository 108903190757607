<template>
    <data-loading-component v-if="dataLoading"/>
    <div class="of-h" v-else>
        <div v-if="details">
            <div>
                <courseoffered-banner :details="details.banners"></courseoffered-banner>
            </div>
            <div>
                <our-course :training_center_i="training_center" :search_i="search"
                            @filterTrainingCenter="filterTrainingCenter"
                            @filterSearch="filterSearch"
                            @viewAll="viewAll"
                            :details="details.training_programs"></our-course>
            </div>

            <div>
                <training-schedule v-if="details.training_schedule"
                                   :details="details.training_schedule"></training-schedule>
            </div>
            <div>
                <course-offer-botttom></course-offer-botttom>
            </div>
        </div>
    </div>
</template>
<script>

import CourseofferedBanner from '../components/course-offered/Courseoffered-Banner';
import CourseOfferBotttom from '../components/course-offered/Courseoffer-Bottom';
import OurCourse from '../components/course-offered/OurCourse';
import axios from 'secure-axios';
import urls from '../data/urls';
import TrainingSchedule from '../components/course-offered/TrainingSchedule';
import DataLoadingComponent from '../components/ui/DataLoadingComponent';

export default {
    name: 'CourseOffered',
    components: { DataLoadingComponent, TrainingSchedule, OurCourse, CourseOfferBotttom, CourseofferedBanner },

    data () {
        return {
            details: '',
            training_center: '',
            search: '',
            per_page: 6,
            dataLoading: false,
            filterApplied: false
        };
    },

    beforeMount () {
        this.loadDetails();
    },

    mounted () {
        this.scrollToTop();
    },

    methods: {
        scrollToTop () {
            window.scrollTo(0, 0);
        },
        filterTrainingCenter (center) {
            this.filterApplied = true;
            this.training_center = center;
            this.loadDetails();
        },
        filterSearch (search) {
            this.filterApplied = true;
            this.search = search;
            this.loadDetails();
        },
        viewAll (search) {
            this.per_page = '';
            this.loadDetails();
        },
        async loadDetails () {
            if (this.$route.hash && !this.filterApplied) {
                this.training_center = this.$route.hash.slice(1);
            }
            this.dataLoading = true;
            const response = await axios.get(urls.website.course.coursesOffered, {
                params: {
                    training_center: this.training_center,
                    search: this.search,
                    per_page: this.per_page
                }
            });
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
            this.$emit('loadingSuccess');
        }
    }
};
</script>

<style scoped>

</style>
