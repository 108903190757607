<template>
    <td v-if="field.slot" :class="className">
        <slot
            :name="field.slot"
            :row-data="item"
            :row-index="rowIndex"
            :row-field="field.name"/>
    </td>

    <td v-else-if="extractName(field.name) === '__slot'" :class="className">
        <slot
            :name="extractArgs(field.name)"
            :row-data="item"
            :row-index="rowIndex"
            :row-field="field.sortField"/>
    </td>

    <td v-else
        :class="className"
        v-html="getObjectValue(item, field.name, '')"
        @click="onCellClicked(item, field, $event)"
        @dblclick="onCellDoubleClicked(item, field, $event)"
        @contextmenu="onCellRightClicked(item, field, $event)"/>
</template>

<script>
export default {
    props: {
        className: {
            type: [String, Array],
            default: '',
            required: false
        },

        field: {
            type: Object,
            default () {
                return {
                    slot: null,
                    name: ''
                };
            },
            required: true
        },

        rowIndex: {
            type: Number,
            required: false,
            default: 0
        },

        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        onCellRightClicked (item, field, $event) {
            this.$emit('cell-right-clicked', item, field, $event);
        },

        onCellClicked (item, field, $event) {
            this.$emit('cell-clicked', item, field, $event);
        },

        onCellDoubleClicked (item, field, $event) {
            this.$emit('cell-double-clicked', item, field, $event);
        },

        getObjectValue (object, path, defaultValue) {
            defaultValue = (typeof defaultValue === 'undefined') ? null : defaultValue;

            let obj = object;
            if (path.trim() !== '') {
                const keys = path.split('.');
                keys.forEach(function (key) {
                    if (obj !== null && typeof obj[key] !== 'undefined' && obj[key] !== null) {
                        obj = obj[key];
                    } else {
                        obj = defaultValue;
                    }
                });
            }
            return obj;
        },

        extractArgs (string) {
            return string.split(':')[1];
        },

        extractName (string) {
            return string.split(':')[0].trim();
        }
    }
};
</script>
