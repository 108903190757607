<template>
    <vue-table-card title="Our Courses" :show-search-box="false" :fields="fields" :url="listUrl" :per-page="10"
                    ref="table">
        <template #buttons>
            <btn color="success" icon="fa fa-plus" size="sm" @click="$refs.addModal.show()" text="Add"/>
        </template>
        <template #course_image="{rowData}">
            <div class="container-image" @click="viewFile(rowData.course_image)">
                <img :src="rowData.course_image" alt="Avatar" class="c-image">
                <div class="c-overlay">
                    <div class="c-icon">
                        <i class="fa fa-eye"></i>
                    </div>
                </div>
            </div>
        </template>
        <template #status="{rowData}">
            <p>{{ rowData.status }}</p>
        </template>
        <template #start_on="{rowData}">
            <p>{{ formatDate(rowData.start_on) }}</p>
        </template>
        <template #end_on="{rowData}">
            <p>{{ formatDate(rowData.end_on) }}</p>
        </template>
        <template #published="{rowData}">
            <p v-if="rowData.published">Yes</p>
            <p v-else>No</p>
        </template>
        <template #tax_percentage="{rowData}">
            <p>{{ rowData.tax_percentage }}%</p>
        </template>

        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn size="xs" text="View" @click="viewDetails(rowData.id)"/>
                <delete-btn @click="setDelete(rowData)" v-if="!rowData.published"/>
                <btn :loading="loading" loading-text="Publishing..." v-if="!rowData.published" size="xs"
                     text="Publish" @click="publishCourse(rowData)"/>
                <btn v-else size="xs" color="secondary" text="Published"/>
            </div>
        </template>

        <template #footer>
            <modal title="Add Course" ref="addModal" width="40r" header-color="primary" no-close-on-backdrop>
                <add-course @success="formSuccess"/>
            </modal>

            <modal title="Edit Course" ref="editModal" width="40r" header-color="primary" no-close-on-backdrop>
                <edit-course :initial-data="editingItem" @success="formSuccess"/>
            </modal>

            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                <p>You are about to delete the Course <b v-html="deletingItem && deletingItem.name"></b>. Are you sure ?
                </p>

                <template #loading>
                    <loading-animation/>
                    Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                </template>
            </delete-modal>
        </template>

    </vue-table-card>
</template>

<script>
import urls from '../../../data/urls';
import AddCourse from './AddOurCourses';
import EditCourse from './EditOurCourses';
import axios from 'secure-axios';

export default {
    name: 'OurCourses',
    components: { EditCourse, AddCourse },
    data () {
        return {
            listUrl: urls.admin.course.list,
            addUrl: urls.admin.course.addEdit,
            deleteUrl: urls.admin.course.delete,
            editingItem: null,
            deletingItem: null,
            loading: false,
            fields: [
                {
                    name: 'name',
                    sortField: 'name',
                    titleClass: 'w-25r',
                    title: 'Name'
                },
                {
                    name: '__slot:course_image',
                    title: 'Course Image'
                },
                {
                    name: '__slot:start_on',
                    title: 'Starts On'
                },
                {
                    name: '__slot:end_on',
                    title: 'Ends On'
                },
                {
                    name: '__slot:status',
                    title: 'Status'
                },
                {
                    name: '__slot:published',
                    title: 'Published'
                },
                {
                    name: '__slot:tax_percentage',
                    title: 'Tax Percentage'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ]
        };
    },
    methods: {
        formatDate (dateString) {
            if (dateString) {
                if (!dateString) return '';
                const parts = dateString.split('T')[0].split('-');
                return parts[2] + '-' + parts[1] + '-' + parts[0];
            }
        },
        async publishCourse (item) {
            if (Number(item.course_fee) < 1) {
                this.$notify('Course fee should not be less than 1', 'Warning', {
                    type: 'warning'
                });
                return;
            }

            this.loading = true;
            const response = await axios.form(urls.admin.course.publish, { id: item.id });
            const json = response.data;
            if (json.error === false) {
                this.$notify(json.msg, 'Success', {
                    type: 'success'
                });
                const refs = this.$refs;
                refs.table.refreshTable();
            } else {
                if (json.msg) {
                    this.$notify(json.msg, 'Error', {
                        type: 'danger'
                    });
                } else {
                    this.$notify('Could not load data, Please try again later', 'Error', {
                        type: 'danger'
                    });
                }
            }
            this.loading = false;
        },
        viewFile (file) {
            window.open(file);
        },
        viewDetails (id) {
            this.$router.push('/admin/course/' + id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.editModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        }
    }
};
</script>
