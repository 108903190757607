<template>
    <vue-table-card title="State" :fields="fields" :url="listUrl" :per-page="10" ref="table">
        <template #buttons>
            <btn color="success" icon="fa fa-plus" size="sm" @click="$refs.addModal.show()" text="Add"/>
        </template>

        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <delete-btn @click="setDelete(rowData)"/>
                <edit-btn @click="setEdit(rowData)"/>
            </div>
        </template>

        <template #footer>
            <modal title="Add State" ref="addModal" width="30r" header-color="primary" no-close-on-backdrop>
                <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
                    <validated-input label="State" class="required-field"
                                     v-model="model.name"
                                     :rules="rules.state"
                                     :disabled="loading"/>
                    <btn text="Save" loading-text="Saving..." size="sm" :disabled="loading" :loading="loading"/>
                </b-form>
            </modal>

            <modal title="Edit State" ref="editModal" width="30r" header-color="primary" no-close-on-backdrop>
                <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" :initial-data="editingItem">
                    <validated-input label="State"
                                     v-model="model.name"
                                     :rules="rules.state"
                                     :disabled="loading"/>
                    <btn text="Update" loading-text="Updating..." size="sm" :disabled="loading" :loading="loading"/>
                </b-form>
            </modal>

            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                <p>You are about to delete the state <b v-html="deletingItem && deletingItem.name"></b>. Are you sure ?</p>

                <template #loading>
                    <loading-animation/>
                    Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                </template>
            </delete-modal>
        </template>

    </vue-table-card>
</template>

<script>
import urls from '@/data/urls';

export default {
    name: 'State',
    data () {
        return {
            listUrl: urls.masterData.State.list,
            addUrl: urls.masterData.State.addEdit,
            deleteUrl: urls.masterData.State.delete,
            editingItem: null,
            deletingItem: null,
            fields: [
                {
                    name: 'name',
                    sortField: 'name',
                    title: 'State'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ],
            rules: {
                state: {
                    required: true
                }
            }
        };
    },
    methods: {
        refresh () {
            const refs = this.$refs;
            refs.table.refreshTable();
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.editModal.close();
            this.$emit('refresh');
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$emit('refresh');
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>
