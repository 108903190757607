<template>
    <div class="mb-3 border-r-2 ol-1 pos-r">
        <div v-if="linkBack" @click="$router.go(-1)"
             class="text-white text-decoration-none cursor-pointer pos-a-lc c-circle fl-x-cc">
            <img src="../../../assets/web/course-offered/greeen-arrow.png" alt="" class="icon-sm-1">
        </div>
        <div class="pt-3 pb-2 px-5 fl-x bg-4 text-1 text-center">
            <h5 class="font-jost-semi fs-lg-4 w-100">{{heading}}</h5>
        </div>
        <section class="fs--1 py-4 font-inter-regular"
                 :class="{'px-lg-4 px-3': horzPadding, 'c-scroll thin-scrollbar':scrollbar}">
            <slot></slot>
        </section>
    </div>
</template>
<script>

// import HeaderTag from '../../../components/ui/HeaderTag';

export default {
    name: 'InnerPageCard',
    components: { },
    props: {
        heading: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        scrollbar: {
            type: Boolean,
            default: false
        },
        headerBullet: {
            type: Boolean,
            default: false
        },
        alignEnd: {
            type: Boolean,
            default: false
        },
        horzPadding: {
            type: Boolean,
            default: true
        },
        linkBack: {
            type: Boolean,
            default: false
        }
    }
};
</script>
<style scoped lang="scss">
.c-bullet {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: -0.8rem;
        top: 50%;
        transform: translateY(-50%);
        width: 0.4rem;
        height: 0.4rem;
        background-color: var(--color-success);
        border-radius: 50%;
    }

}

.c-circle {
    width: 24px;
    height: 24px;
    //background: var(--color-secondary-600);
    //background: #1a41a2 !important;
    border-radius: 50%;

    &.circle-dark {
        background: #1a41a2 !important;
    }
}

.c-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 80vh;
}
</style>
