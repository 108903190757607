<template>
    <div class="bg-7 ">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-12 map">
                    <div class="mt-lg-1 mt-8 pt-5 pt-lg-1">
                        <p class="font-lato-semi-bold fs--1 mb-21" align="center">MAPS AND DIRECTIONS</p>
                        <h4 class="font-jost-semi-bold fs-5" align="center">Our Training Centers</h4>
                    </div>
                    <div class="c-tabs header-semi-bold">
                        <div v-if="loading" class="text-center">
                            <loading-animation/>
                            Loading data...
                        </div>
                        <tabs v-else align="center" class="c-tabs mb-5 d-sm-none pt-lg-1 mt-lg-0">
                            <div class="tab-header-container">
                                <div v-for="(i, index) in details" :key="index">
                                    <tab :title="i.location" class="fs--1">
                                        <iframe class="w-100 py-4" height="400"
                                                :src="'https://maps.google.com/maps?q=' + i.latitude + ',' + i.longitude + '&hl=es;z=14&amp;output=embed'"
                                                style="border:0;" allowfullscreen="" loading="lazy"
                                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                                        <div class="fl-x fl-j-c">
                                            <div class="row pt-4 ic">
                                                <div class="fl-x fl-a-c fl-x-res mr-md-4">
                                                    <div class="fl-j-s-md-c-lg w-md-100 mb-3 mb-lg-0">
                                                        <div class="mappp ml-5 mr-4 ic cursor-pointer" align="center"
                                                             @click="viewLocation(i)">
                                                        </div>
                                                    </div>
                                                    <div class="p-0 text-2 mr-5 ic cursor-pointer"
                                                         @click="viewLocation(i)">
                                                        <h6 class="font-lato-semi-bold">Area Location</h6>
                                                        <p class="fs--1 mb-0 font-lato-medium">{{ i.name }},<br/>
                                                            {{ i.location }}, {{ i.state_name }}<br>
                                                            Pin : {{ i.pincode }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="fl-x fl-a-c fl-x-res mr-md-14">
                                                    <div class="fl-j-s-md-c-lg w-md-100 mt-lg-0 mb-3 mb-lg-0">
                                                        <div class="contacti sm-mt ml-5 mr-1 space mt-0 ic"
                                                             align="center"></div>
                                                    </div>
                                                    <div class="p-0 text-2 ml-4 mr-7 ic">
                                                        <h6 class="font-lato-semi-bold">Contact Details </h6>
                                                        <p class="fs--1 font-lato-medium">
                                                            Phone : {{ i.phone }}<br/>
                                                            E-Mail : {{ i.email }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="fl-x fl-a-c fl-x-res mr-md-4">
                                                    <div class="fl-j-s-md-c-lg w-md-100 mb-3 mb-lg-0">
                                                        <div class="timei sm-mt ml-3 mt-0 mr-4 ic mb-sm-3"
                                                             align="center"></div>
                                                    </div>
                                                    <div class="p-0 text-2 ic pb">
                                                        <h6 class="font-lato-semi-bold">Opening Hour</h6>
                                                        <p class="fs--1 font-lato-medium">
                                                            Opening Days : {{ i.opening_days }} days<br/>
                                                            Opening Hours : {{ i.opening_hours }} hrs
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </tab>
                                </div>
                            </div>
                        </tabs>
                        <tabs class="text-center c-tabs-22 d-lg-none header-column-design-on-mobile">
                            <div v-for="(location, index) in details" :key="index">
                                <tab :title="location.location" class="">
                                    <iframe class="w-100 py-4" height="400"
                                            :src="'https://maps.google.com/maps?q=' + location.latitude + ',' + location.longitude + '&hl=es;z=14&amp;output=embed'"
                                            style="border:0;" allowfullscreen="" loading="lazy"
                                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    <div class="p-0 text-2 mr-7 ic">
                                        <div class="fl-x fl-a-c fl-x-res mr-md-4">
                                            <div class="fl-j-s-md-c-lg w-md-100 mb-3 mb-lg-0">
                                                <div class="mappp ml-5 mr-4 ic cursor-pointer" align="center"
                                                     @click="viewLocation(location)">
                                                </div>
                                            </div>
                                            <div class="p-0 text-2 mr-5 ic cursor-pointer"
                                                 @click="viewLocation(location)">
                                                <h6 class="font-lato-semi-bold">Area Location</h6>
                                                <p class="fs--1 mb-0 font-lato-medium">{{ location.name }},<br/>
                                                    {{ location.location }}, {{ location.state_name }}<br/>
                                                    Pin : {{ location.pincode }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="fl-x fl-a-c fl-x-res mr-md-14">
                                            <div class="fl-j-s-md-c-lg w-md-100 mt-lg-0 mb-3 mb-lg-0">
                                                <div class="contacti sm-mt ml-5 mr-1 space mt-0 ic"
                                                     align="center"></div>
                                            </div>
                                            <div class="p-0 text-2 ml-4 mr-7 ic">
                                                <h6 class="font-lato-semi-bold">Contact Details</h6>
                                                <p class="fs--1 font-lato-medium">
                                                    Phone : {{ location.phone }}<br/>
                                                    E-Mail : {{ location.email }}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="fl-x fl-a-c fl-x-res mr-md-4">
                                            <div class="fl-j-s-md-c-lg w-md-100 mb-3 mb-lg-0">
                                                <div class="timei sm-mt ml-3 mt-3 mr-4 ic mb-sm-3"
                                                     align="center"></div>
                                            </div>
                                            <div class="p-0 text-2 ic pb">
                                                <h6 class="font-lato-semi-bold">Opening Hour</h6>
                                                <p class="fs--1 font-lato-medium">
                                                    Opening Days : {{ location.opening_days }} days<br/>
                                                    Opening Hours : {{ location.opening_hours }} hrs
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </tab>
                            </div>
                        </tabs>

                        <!--                        <tabs class="text-center c-tabs-22 d-lg-none header-column-design-on-mobile">-->
                        <!--                            <div v-for="(location, index) in details" :key="index">-->
                        <!--                                <tab :title="location.location" class="">-->
                        <!--                                    <iframe class="w-100 py-4" height="400"-->
                        <!--                                        :src="'https://maps.google.com/maps?q=' + location.latitude + ',' + location.longitude + '&hl=es;z=14&amp;output=embed'"-->
                        <!--                                        style="border:0;" allowfullscreen="" loading="lazy"-->
                        <!--                                        referrerpolicy="no-referrer-when-downgrade"></iframe>-->
                        <!--                                    <div class="p-0 text-2 mr-7 ic">-->
                        <!--                                        <h6 class="font-lato-semi-bold">Area Location</h6>-->
                        <!--                                        <p class="fs&#45;&#45;1 mb-0 font-lato-medium">{{ location.name }},<br />-->
                        <!--                                            {{ location.location }}, {{ location.state_name }}<br />-->
                        <!--                                            Pin : {{ location.pincode }}-->
                        <!--                                        </p>-->
                        <!--                                        <h6 class="font-lato-semi-bold">Contact Details</h6>-->
                        <!--                                        <p class="fs&#45;&#45;1 font-lato-medium">-->
                        <!--                                            Phone : {{ location.phone }}<br />-->
                        <!--                                            E-Mail : {{ location.email }}-->
                        <!--                                        </p>-->
                        <!--                                        <h6 class="font-lato-semi-bold">Opening Hour</h6>-->
                        <!--                                        <p class="fs&#45;&#45;1 font-lato-medium">-->
                        <!--                                            Opening Days : {{ location.opening_days }} days<br />-->
                        <!--                                            Opening Hours : {{ location.opening_hours }} hrs-->
                        <!--                                        </p>-->
                        <!--                                    </div>-->
                        <!--                                </tab>-->
                        <!--                            </div>-->
                        <!--                        </tabs>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TrainingCenterMap',
    props: {
        details: { type: Array }
    },
    data () {
        return {
            loading: true
        };
    },
    mounted () {
        this.loadingFunction();
    },
    methods: {
        viewLocation (i) {
            window.open('https://maps.google.com/maps?q=' + i.latitude + ',' + i.longitude + '&hl=es;z=14&amp;output=embed');
        },
        loadingFunction () {
            setTimeout(this.stopLoading, 500);
        },
        stopLoading () {
            this.loading = false;
        }
    }
};
</script>

<style scoped>
.space {
    padding-top: 0px;
}

.mappp {
    background-image: url('../../assets/web/contact us/map.png');
    height: 54px;
    width: 55px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.contacti {
    background-image: url('../../assets/web/contact us/phone.png');
    height: 55px;
    width: 55px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.timei {
    background-image: url('../../assets/web/contact us/timee.png');
    height: 55px;
    width: 55px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

h6 {
    color: black;
}

.tab-header-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the headers */
}

.c-tabs .tab-header-container .tab {
    flex: 1 0 16.66%; /* Allow 6 items per row */
    min-width: 150px; /* Prevent headers from being too narrow */
    box-sizing: border-box; /* Ensure padding/margins don't affect width */
}

@media (max-width: 750px) {
    .space {
        margin-top: 2rem !important;
    }

    .mappp {
        height: 45px;
        width: 45px;
    }

    .contacti {
        height: 35px;
        width: 35px;
    }

    .timei {
        height: 35px;
        width: 35px;
    }

    .ic {
        margin-right: 0 !important;
        margin-left: 0 !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .pb {
        padding-top: 9px !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .contacti {
        height: 45px;
        width: 45px;
    }

    .timei {
        height: 45px;
        width: 45px;
    }

    .ic {
        margin-right: 2px !important;
        margin-left: 11px !important;
    }

    .pb {
        padding-top: 0 !important;
    }

    .space {
        margin-top: 0;
    }
}
</style>
