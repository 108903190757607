<template>
    <vue-table-card title="Social Media" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                    :show-search-box="false">
        <template #buttons>
            <btn color="success" icon="fa fa-plus" size="sm" @click="$refs.addModal.show()" text="Add"/>
        </template>

        <template #is_enabled="{rowData}">
            <span v-if="rowData.is_enabled">Yes</span>
            <span v-else>No</span>
        </template>

        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <delete-btn @click="setDelete(rowData)"/>
                <edit-btn @click="setEdit(rowData)"/>
            </div>
        </template>

        <template #footer>
            <modal title="Add Social Media" ref="addModal" width="40r" header-color="primary" no-close-on-backdrop>
                <b-form :save-url="addUrl" @success="formSuccess('Added')" v-slot="{model, loading}">
                    <div class="row">
                        <div class="col-6">
                            <validated-input label="Social Media" v-model="model.name" class="required-field"
                                             :rules="{required: true, max:20}" :disabled="loading"/>
                        </div>
                        <div class="col-6">
                            <validated-vue-select :options="typeOptions" label="Social Media Type"
                                                  class="required-field"
                                                  v-model="model.type"
                                                  :rules="{required: true}" :disabled="loading"/>
                        </div>
                        <div class="col-6">
                            <validated-input label="Link" v-model="model.link" class="required-field"
                                             :rules="{required: true}" :disabled="loading"/>
                        </div>
                        <div class="col-6">
                            <validated-input type="number" label="Order" v-model="model.order"
                                             :rules="{required: false, min_value:1}" :disabled="loading"/>
                        </div>
                        <div class="col-6">
                            <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                                :rules="{required: false}" :disabled="loading"/>
                        </div>
                        <div class="col-12 text-right">
                            <btn class="trn" text="Save" loading-text="Saving..." size="sm" :disabled="loading"
                                 :loading="loading"/>
                        </div>
                    </div>
                </b-form>
            </modal>

            <modal title="Edit Social Media" ref="editModal" width="40r" header-color="primary" no-close-on-backdrop>
                <b-form :save-url="addUrl" @success="formSuccess('Updated')" v-slot="{model, loading}"
                        :initial-data="editingItem">
                    <div class="row">
                        <div class="col-6">
                            <validated-input label="Social Media" v-model="model.name"
                                             :rules="{required: true, max:20}" :disabled="loading"/>
                        </div>
                        <div class="col-6">
                            <validated-vue-select :options="typeOptions" label="Social Media Type"
                                                  v-model="model.type"
                                                  :rules="{required: true}" :disabled="loading"/>
                        </div>
                        <div class="col-6">
                            <validated-input label="Link" v-model="model.link"
                                             :rules="{required: true}" :disabled="loading"/>
                        </div>
                        <div class="col-6">
                            <validated-input type="number" label="Order" v-model="model.order"
                                             :rules="{required: false, min_value:1}" :disabled="loading"/>
                        </div>
                        <div class="col-6">
                            <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                                :rules="{required: false}" :disabled="loading"/>
                        </div>
                        <div class="col-12 text-right">
                            <btn class="trn" text="Update" loading-text="Updating..." size="sm" :disabled="loading"
                                 :loading="loading"/>
                        </div>
                    </div>
                </b-form>
            </modal>

            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                <p>You are about to delete the Social Media <b v-html="deletingItem && deletingItem.name"></b>. Are you
                    sure ?</p>
                <template #loading>
                    <loading-animation/>
                    Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                </template>
            </delete-modal>
        </template>

    </vue-table-card>
</template>

<script>
import urls from '../../../data/urls';

export default {
    name: 'SocialMedia',

    data () {
        return {
            listUrl: urls.admin.socialMedia.list,
            addUrl: urls.admin.socialMedia.addEdit,
            deleteUrl: urls.admin.socialMedia.delete,
            typeOptions: [
                { label: 'Whatsapp', value: 'Whatsapp' },
                { label: 'Facebook', value: 'Facebook' },
                { label: 'Instagram', value: 'Instagram' },
                { label: 'Twitter', value: 'Twitter' },
                { label: 'LinkedIn', value: 'LinkedIn' },
                { label: 'Google Plus', value: 'Google Plus' },
                { label: 'Youtube', value: 'Youtube' },
                { label: 'WeChat', value: 'WeChat' },
                { label: 'Sina Weibo', value: 'Sina Weibo' },
                { label: 'QQ', value: 'QQ' },
                { label: 'Telegram', value: 'Telegram' },
                { label: 'Pinterest', value: 'Pinterest' },
                { label: 'SnapChat', value: 'SnapChat' },
                { label: 'Reddit', value: 'Reddit' },
                { label: 'Quora', value: 'Quora' },
                { label: 'Skype', value: 'Skype' },
                { label: 'Microsoft Teams', value: 'Microsoft Teams' }
            ],
            editingItem: null,
            deletingItem: null,

            fields: [
                {
                    name: 'order',
                    sortField: '',
                    title: 'Order'
                },
                {
                    name: 'name',
                    sortField: '',
                    title: 'Social Media'
                },
                {
                    name: 'type',
                    sortField: '',
                    title: 'Social Media Type'
                },
                {
                    name: 'created_at',
                    sortField: 'created_at',
                    title: 'Created At'
                },
                {
                    name: '__slot:is_enabled',
                    title: 'Enabled'
                },
                {
                    name: '__slot:actions',
                    title: 'Actions',
                    titleClass: 'center bg-gray aligned text-right',
                    dataClass: 'center aligned text-right'
                }
            ]
        };
    },
    methods: {
        refresh () {
            const refs = this.$refs;
            refs.table.refreshTable();
        },
        formSuccess (action) {
            const refs = this.$refs;
            refs.addModal.close();
            refs.editModal.close();
            this.$emit('refresh');
            this.$notify((action || 'Processed') + ' Successfully', 'Success', {
                type: 'success'
            });
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$emit('refresh');
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>
