import { render, staticRenderFns } from "./EditWhyChooseUs.vue?vue&type=template&id=006c3484&scoped=true&"
import script from "./EditWhyChooseUs.vue?vue&type=script&lang=js&"
export * from "./EditWhyChooseUs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "006c3484",
  null
  
)

export default component.exports