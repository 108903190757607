<template>
    <div>
        <div class="card bs-4">
            <div class="fl-te-c">
                <h4 class="mb-3">About Us Details</h4>
                <btn text="Back" @click="$router.push('/admin/about-us/')" size="sm"/>
            </div>
            <div v-if="loading" class="text-center py-5">
                <loading-animation/>
                Please wait while data is being loaded...
            </div>
            <edit-about-us v-if="details && !loading" :initial-data="details" @success="loadDetails"/>
            <p v-if="!details && !loading" class="text-center p-3">No data available.</p>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../../data/urls';
import EditAboutUs from './EditAboutUs';

export default {
    name: 'DetailsPage',
    components: { EditAboutUs },
    data () {
        return {
            loading: false,
            details: '',
            id: this.$route.params.id
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            this.loading = true;
            const response = await axios.form(urls.admin.aboutUs.details, { id: this.id });
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.loading = false;
        }
    }
};
</script>

<style scoped>

</style>
