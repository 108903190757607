<template>
    <!--    <div class="item bg-cover" :style="{ backgroundImage: 'url(' + image + ')' }">-->
    <div class="item bg-cover pos-r h-100">
        <div class="overlay-dark"></div>
        <div class="container h-100 py-4 pt-lg-9 pt-9">
            <div class="row h-100">
                <div class="col-12  col-md-6 col-lg-6 my-1 h-100 my-lg-0 text-white">
                    <div class="fl-x fl-a-e h-100">
                        <div>
                            <p class="mb-lg-3 mb-2 fs-lg--1 font-lato-semi-bold text-uppercase">Education Benefits</p>
                            <div class="w-90p">
                                <h6 class="fs-1 font-lato-bold text-white fs-lg-10 mb-lg-3 mb-2 d-inline-block w-90p m-0 p-0"
                                    data-swiper-parallax="-600">
                                    Limitless Learning And <br> Get More Possibilities<br/>
                                </h6>
<!--                                <p class="m-0 mb-2 mb-lg-3">-->
<!--                            <span data-swiper-parallax="-400" class="w-inh fs-lg&#45;&#45;1">-->
<!--                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cupiditate doloremque eaque earum excepturi explicabo hic laborum minima molestias-->
<!--                            </span>-->
<!--                                </p>-->
                            </div>
                            <!--                            <div data-swiper-parallax="-300" class="fl-x">-->
                            <!--                                <router-link to="/"-->
                            <!--                                             class="fl-x fl-a-c mr-2 text-decoration-none btn xeo-btn-5 fs&#45;&#45;2 fs-lg-0 font-inter-light">-->
                            <!--                                    <img src="../../assets/web/landing/arrow.png" alt=""-->
                            <!--                                         style="transform: rotate(-180deg)" class="icon-md">-->
                            <!--                                </router-link>-->
                            <!--                                <router-link to="/"-->
                            <!--                                             class="fl-x fl-a-c text-decoration-none btn xeo-btn-5 fs&#45;&#45;2 fs-lg-0 font-inter-light">-->
                            <!--                                    <img src="../../assets/web/landing/arrow.png" class="icon-md" alt="">-->
                            <!--                                </router-link>-->
                            <!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'CourseOffer-Botttom',
    props: {
        image: {
            type: Image,
            default: null
        }
    }
};
</script>

<style scoped lang="scss">
.bg-cover {
    //background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../assets/web/landing/video-img.jpg");
    background-position: 0% 0%;
    @media (min-width: 992px) {
        background-position: unset;
    }
}

.overlay-dark {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}
</style>
