<template>
    <div>
        <div class="container pl-0">
            <div class="row pr-lg-5">
                <div class="col-12 col-lg-12">
                    <div title="Faq" class="">
                        <div class="col-12 col-lg-11 p-0">
                            <!--                                <img src="../../assets/web/course-offered/bgi.jpg" class="img-fluid" alt="">-->
                            <p class="font-jost-medium fs-1 text-black mb-4 pr-lg-5 text-justify">FREQUENTLY ASKED QUESTION
                            </p>
                        </div>
                    </div>
                </div>
                <div v-for="(i, index) in details" :key="index" class="col-12 col-lg-11 ">
                    <div class="fl-y fl-a-s">
                        <base-accordion color="white" class="bb-1 bt-1 text-justify font-jost-semi-bold" :heading="i.question">
                            <template #default>
                                <div class="fl-x fl-j-s ml-5">
                                    <p class="font-jost-medium text-justify fs-1">{{i.answer}}</p>
                                </div>
                            </template>
                        </base-accordion>
                    </div>
                </div>
            </div>
        </div>
        <!--        <btn @click="$refs.NotLoggedInModal.show()">Open Modal</btn>-->
        <modal class="c-modal" :no-close-on-backdrop="false" title=""
               ref="NotLoggedInModal" width="25r" header-color="">
            <div class="text-center px-lg-5 pb-5 pt-4">
                <div class="fl-x fl-j-c mb-4">
                    <div class="custom-info-icon"
                         style="">
                        i
                    </div>
                </div>

                <p class="font-lato-semi-bold fs-lg-4 mb-4">You're not logged in !</p>
                <p class="fs--1 mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo, natus! Lorem ipsum
                    dolor sit.</p>
                <router-link to="/login/">
                    <btn text="LOGIN NOW" border-radius="0" class="px-5"></btn>
                </router-link>
            </div>
        </modal>

    </div>
</template>

<script>
// import BaseBanner from '@components/ui/BaseBanner';
import bannerImg from '../../assets/web/course-offered/bgi.jpg';
import img1 from '../../assets/web/landing/our-courses/course-1.jpg';
import img2 from '../../assets/web/landing/our-courses/course-2.jpg';
import img3 from '../../assets/web/landing/our-courses/course-3.jpg';

import BaseAccordion from '@components/ui/BaseAccordion';

export default {
    name: 'syllabus',
    props: {
        details: {
            type: Array, default: null
        }
    },
    components: { BaseAccordion },
    data () {
        return {
            bannerImg: bannerImg,
            img1: img1,
            img2: img2,
            img3: img3,
            incidentFields: [
                {
                    name: 'particulars',
                    title: 'Particulars'
                },
                {
                    name: 'days',
                    title: 'Days'
                },
                {
                    name: 'rate',
                    title: 'Rate/Unit'
                },
                {
                    name: 'rate1',
                    title: 'Rate/Unit'
                }
            ],
            dataItems: [
                {
                    particulars: 'Lodging Charges',
                    days: 10,
                    rate: 'Rs. 100/day/person',
                    rate1: '1000'
                },
                {
                    particulars: 'Boarding Charges',
                    days: 10,
                    rate: 'Rs. 500/day/person',
                    rate1: '5000'
                },
                {
                    particulars: 'Tuition Fee',
                    days: 10,
                    rate: 'Rs. 25000/person',
                    rate1: '25000'
                }
            ]
        };
    }
};
</script>

<style scoped lang="scss">
.im-2 img {
    height: 150px;
    width: 150px;
}

.custom-ul-list {
    list-style: none;

    li {
        position: relative;

        &:before {
            content: "";
            width: 5px;
            height: 5px;
            background-color: var(--color-primary);
            border-radius: 50%;
            //transform: translateY(-40%);
            top: 9px;
            left: 0;
            position: absolute;
        }

        &:after {
            content: "";
            position: absolute;
            left: 2px;
            top: 17px;
            width: 1px;
            height: 100%;
            background-color: rgba(173, 189, 183, 0.5);
        }
    }
}

.fss {
    font-size: 22px !important;
}

.pos-a-left-ol {
    @media (min-width: 992px) {
        position: absolute;
        width: 70%;
        left: -4rem;
        top: -5rem;
    }

}
</style>
