<template>
<div>Admin Home Page</div>
</template>

<script>
export default {
    name: 'AdminHome'
};
</script>

<style scoped>

</style>
