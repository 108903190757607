<template>
    <data-loading-component v-if="dataLoading"/>
    <div v-else class="of-h">
        <div v-if="details">
            <about-card-head-img :details="details.banners"></about-card-head-img>
            <div class="about">
                <div class="container">
                    <div class="row">
                        <div class="col-12 ">
                            <aboutus-card-one v-if="details.about_us" :details="details.about_us"></aboutus-card-one>
                        </div>
                        <div class="col-12 contentt text-left pt-lg-3 pt-2 pt-md-2">
                            <section-heading title="OUR PHILOSOPHY" bottom-tag-align="left" class="text-left mt-4"
                                             align="left">

                            </section-heading>
                            <P class="font-jost-semi mt-42 mb-4 lh-3 text-black-1 fs-lg-2 text-center center">
                                We Believe In Our Good
                                Education And Development
                                System And We Know How To<br>
                                Work Together To Reach Greater Success.</P>
                        </div>
                        <div class="col-12 content d-lg-content" v-if="details.our_philosophy.length!==0">
                            <div class="row p-0">
                                <div class="col-lg-3 col-12" v-for="(i, index) in details.our_philosophy" :key="index">
                                    <our-philosophy-card :details="i" :img="i.image" class="d-lg-content m-2">
                                    </our-philosophy-card>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" v-if="details.our_philosophy.length!==0">
                            <our-philosophy-swiper :details=" details.our_philosophy" class="d-lg-none"/>
                        </div>
                    </div>
                </div>
                <div class="pt-7 p-tt" v-if="details.why_choose_us">
                    <about-why-choose-us :details="details.why_choose_us"></about-why-choose-us>
                </div>
            </div>
        </div>
    </div>

</template>
<script>

import OurPhilosophyCard from '../components/about/OurPhilosophyCard';
import AboutusCardOne from '../components/about/AboutusCardOne';
import AboutCardHeadImg from '../components/about/AboutCardHeadImg';
import SectionHeading from '@components/ui/SectionHeading';
import OurPhilosophySwiper from '../components/about/OurPhilosophySwiper';
import img1 from '../assets/web/about-us/img.jpg';
import img2 from '../assets/web/about-us/bgi.jpg';
import img3 from '../assets/web/about-us/course-2.jpg';
import img4 from '../assets/web/about-us/cimage.jpg';
import AboutWhyChooseUs from '../components/about/AboutWhyChooseUs';
import axios from 'secure-axios';
import urls from '../data/urls';
import DataLoadingComponent from '../components/ui/DataLoadingComponent';

export default {
    name: 'About',
    components: {
        DataLoadingComponent,
        AboutWhyChooseUs,
        OurPhilosophySwiper,
        SectionHeading,
        OurPhilosophyCard,
        AboutusCardOne,
        AboutCardHeadImg
    },
    data () {
        return {
            img1: img1,
            img2: img2,
            img3: img3,
            img4: img4,
            details: '',
            dataLoading: false
        };
    },

    beforeMount () {
        this.loadDetails();
    },

    methods: {
        async loadDetails () {
            this.dataLoading = true;
            const response = await axios.get(urls.website.aboutUs);
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
            this.$emit('loadingSuccess');
        }
    }
};
</script>

<style scoped>
.text-center {
    text-align: left !important;
}

@media (max-width: 750px) {
    .contentt {
        padding-left: 2rem;
        padding-right: 2rem;
        align-items: center;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 5px;
    }

    .p-tt {
        padding-top: 47px !important;
    }

    .center {
        text-align: center !important;
    }

    @media (max-width: 750px) {
        .d-lg-content {
            display: none;
        }

        .text-center {
            text-align: center !important;
        }
    }
}
</style>
