<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
        <div class="row">
            <div class="col-12">
                <validated-input label="Heading" v-model="model.heading" class="required-field"
                                 :rules="{required: true, max:30}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-text-area label="Content" v-model="model.content" class="required-field"
                                    :rules="{required: true, max:1000}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <file-input class="c-file-input" ref="file" label="Image" v-model="model.image"
                            :disabled="loading" name="Image">
                </file-input>
                <image-info :width="632" :height="352"/>
            </div>
            <div class="col-6">
                <validated-input type="number" label="Order" v-model="model.order"
                                 :rules="{required: false, min_value:1}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn class="trn" text="Save" loading-text="Saving..." size="sm" :disabled="loading" :loading="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>

import urls from '../../../../data/urls';

export default {
    name: 'AddOurFacility',

    data () {
        return {
            addUrl: urls.admin.ourFacility.addEdit
        };
    },

    methods: {
        formSuccess () {
            this.$notify('Successfully Added Facility..!', 'Success',
                {
                    type: 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
