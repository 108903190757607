<template>
    <div class="bg-3">
        <div class="container py-5">
            <div class="row">
                <div class="col-12">
                    <section-heading title="OUR COURSES"></section-heading>
                </div>
                <div class="col-12">
                    <h2 class="font-jost-semi fs-lg-9 text-center mb-51 mt-2 mt-lg-3">
                        Keep Training And Learn Until <br class="d-lg-none"> You Get Right
                    </h2>
                </div>
                <template v-if="$router.currentRoute.name !== 'Home'">
                    <div v-for="(i, index) in details" :key="index"
                         class="col-12 d-lg-block col-md-6 col-lg-4 d-sm-none mb-4">
                        <course-card :img="i.course_image" :details="i"></course-card>
                    </div>
                </template>
                <template v-else>
                    <div v-for="(i, index) in details.slice(0,3)" :key="index"
                         class="col-12 col-md-6 col-lg-4 d-lg-block mb-4">
                        <course-card :img="i.course_image" :details="i"></course-card>
                    </div>
                </template>
                <div class="col-12">
                    <our-courses-swiper :details="details.slice(0,3)" class="d-lg-none"></our-courses-swiper>
                </div>
                <div class="col-12 text-center">
                    <btn @click="$router.push('/course-offered/')" text="VIEW ALL" border-radius="0"
                         class="c-btn-2 mr-3 mb-lg-0 mt-lg-5 mt-3 font-lato-regular"></btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CourseCard from '../../components/home/CourseCard';
import OurCoursesSwiper from '../../components/home/OurCoursesSwiper';

export default {
    name: 'OurCourses',
    props: {
        details: { type: Array, default: null }
    },
    components: { CourseCard, OurCoursesSwiper },
    data () {
        return {};
    }
};
</script>

<style scoped>

</style>
