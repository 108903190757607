<template>
    <div class="cursor-pointer text-decoration-none bg-2 bs-4 b-1 card-hover">
        <div @click="viewDetails(details.id)" class="card-img-min-h bg-cover"
             :style="cardImage(img)"></div>
        <div class="px-3">
            <div class="fl-te-c pt-3 pb-2">
                <div class="fl-x fl-a-c">
                    <!--                    <img src="../../assets/web/landing/map.png" class="icon-xs" alt="">-->
                    <span class="ml-2 fs--2 ml-2 text-2 font-lato-regular">{{ details.application_status }}</span>
                </div>
                <div @click="$router.push('/training-centers/#item' + details.training_location)"
                     class="fl-x fl-a-c cursor-pointer">
                    <img src="../../assets/web/landing/map.png" class="icon-xs" alt="">
                    <span class="ml-2 fs--2 ml-2 text-2 font-lato-regular">{{ details.training_location_name }}</span>
                </div>
            </div>
            <div @click="viewDetails(details.id)" class="min-lg-12re mt-2">
                <h4 class="font-jost-semi fs-2">
                    {{ details.name }}
                </h4>
                <ul class="list-unstyled mt-3 font-lato-medium">
                    <li class="fl-x mb-2">
                        <span class="fl-x fl-a-c fl-j-s d-inline-block min-w-1re"><img
                            src="../../assets/web/course-offered/apply-now/Application-closes.png"
                            class="icon-xs-0" alt=""></span>
                        <span v-if="details.application_status!=='Application Closed'"
                              class="ml-2 text-2 fs--2 font-lato-medium">APPLICATION CLOSES : {{
                                formatDate(details.application_end_date)
                            }}</span>
                        <span v-else class="ml-2 text-2 fs--2 font-lato-medium">APPLICATION CLOSED ON {{
                                formatDate(details.application_end_date)
                            }}</span>
                    </li>
                    <li class="fl-x mb-2 mt-1">
                        <span class="ml-1 fl-x fl-a-c fl-j-s d-inline-block min-w-1re"><img
                            src="../../assets/web/course-offered/clock.png" class="icon-xs-0"
                            alt=""></span>
                        <span class="ml-2 text-2 fs--2 font-lato-medium">DURATION : {{
                                details.duration_formatted
                            }}</span>
                    </li>
                    <li class="fl-x mb-2 fl-a-c mt-1">
                        <span class="ml-1 fl-x fl-a-c fl-j-s d-inline-block min-w-1re"><img
                            src="../../assets/web/course-offered/rupee.png" class="icon-xs-0"
                            alt=""></span>
                        <span class="ml-2 text-2 fs--2 font-lato-medium">COURSE FEE : Rs. {{
                                details.course_fee
                            }} /-</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="text-center py-2 c-bt-2 mt-2">
            <div class="text-decoration-none cursor-pointer"
                 @click="viewDetails(details.id)">
                <span class="font-lato-regular">KNOW MORE</span>
                <span class="ml-2">
                    <img src="../../assets/web/landing/icons8-plus-16.png" alt="">
                </span>
            </div>
        </div>

    </div>
</template>
<script>

export default {
    name: 'CourseCard',
    components: {},
    props: {
        details: {
            type: Object,
            default: null
        },
        training_center: {
            type: [String, Number],
            default: null
        },
        heading: {
            type: String,
            default: ''
        },
        source: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'sm'
        },
        author: {
            type: String,
            default: ''
        },
        img: {
            type: String
        },
        content: {
            type: String,
            default: ''
        },
        link: {
            type: String,
            default: '/'
        },
        nextLink: {
            type: String,
            default: '/'
        },
        prevLink: {
            type: String,
            default: '/'
        }
    },
    methods: {
        formatDate (dateString) {
            if (dateString) {
                if (!dateString) return '';
                const parts = dateString.split('T')[0].split('-');
                return parts[2] + '-' + parts[1] + '-' + parts[0];
            }
        },
        cardImage (image) {
            return { backgroundImage: 'url(' + image + ')' };
        },
        viewDetails (id) {
            if (this.training_center) {
                this.$router.push('/course-offered/' + id + '/details/' + '#' + this.training_center).then(() => {
                    this.$router.go(0);
                });
            } else {
                this.$router.push('/course-offered/' + id + '/details/').then(() => {
                    this.$router.go(0);
                });
            }
        }
    }
}; </script>
<style lang="scss" scoped>
.card-img-min-h {
    position: relative;
    height: 10rem;

    img {
        width: 100% !important;
        height: 100% !important;
    }
}

.min-w-1re {
    min-width: 1rem !important;
}

.bg-plus {
    background: url("../../assets/web/landing/icons8-plus-16.png");
    width: 16px;
    height: 16px;
}

.c-bt-2 {
    border-top: var(--border-1) solid rgba(228, 233, 242, 0.4);
}

.card-hover {
    transition: background-color, box-shadow 0.3s ease-in-out;

    &:hover {
        background: var(--bg-3) !important;
        border: none !important;
        box-shadow: none !important;
    }
}
</style>
