<template>
    <base-form-control v-bind="formControlAttributes">
        <slot name="prepend"></slot>
        <v-select ref="vSelect" class="control" v-bind="controlAttributes" :clearable="clearable" v-model="model" :disabled="disabled" :multiple="multiple" v-on="listeners" :options="options"></v-select>
        <slot name="append"></slot>
    </base-form-control>
</template>

<script>
import BaseInput from './BaseInput';
import VueSelectCommons from './VueSelectCommons';

export default {
    name: 'custom-validated-form-select',
    extends: BaseInput,
    mixins: [VueSelectCommons],
    data () {
        return { model: '' };
    },
    props: {
        options: {
            type: Array,
            default () {
                return [];
            }
        }
    },
    mounted () {
        this.findModel();
        const selectedItem = this.$refs.vSelect.$el.querySelector('.vs__selected');
        console.log('selectedItem', selectedItem);
    },
    methods: {}

};
</script>
