<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" :save-params="{course}">
        <div class="row">
            <div class="col-12">
                <validated-input label="Question" v-model="model.question"
                                 :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-text-area label="Answer" v-model="model.answer"
                                     :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-checkbox label="Is Enabled" v-model="model.is_enabled"
                                    :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn class="trn" text="Save" loading-text="Saving..." size="sm" :disabled="loading" :loading="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>

import urls from '../../../../data/urls';

export default {
    props: {
        course: { type: String }
    },
    name: 'AddCourseFAQ',

    data () {
        return {
            addUrl: urls.admin.course.detailsUrl.faq.addEdit
        };
    },

    methods: {
        formSuccess () {
            this.$notify('Successfully Added Item..!', 'Success',
                {
                    type: 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
