<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}" :save-params="{course}">
        <div class="row">
            <div class="col-12">
                <validated-input label="Charge Type" v-model="model.charge_type"
                                 :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-input type="number" label="Days" v-model="model.days"
                                 :rules="{required: true, min_value:1}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-input type="number" label="Rate" v-model="model.rate"
                                 :rules="{required: true, min_value:1}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn class="trn" text="Save" loading-text="Saving..." size="sm" :disabled="loading" :loading="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>

import urls from '../../../../data/urls';

export default {
    props: {
        course: { type: String }
    },
    name: 'AddCourseCharge',

    data () {
        return {
            addUrl: urls.admin.course.detailsUrl.charge.addEdit
        };
    },

    methods: {
        formSuccess (response) {
            this.$notify('Successfully Added Item..!', 'Success',
                {
                    type: 'success'
                }
            );
            this.$emit('success', response);
        }
    }
};
</script>

<style scoped>

</style>
