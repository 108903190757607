<template>
    <div v-if="details.length!==0" class="about-banner m-0 of-h" :style="banner(details[0].banner_image)">
        <div class="row">
            <div class="col-12 col-lg-12 ">
                <div class="section-head">
                    <p class="font-inter-Medium mb-1 fs-2">
                        {{details[0].heading1}}
                    </p>
                    <p class="font-jost-regular fs-7 font-weight-bold ">{{details[0].heading2}}</p>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="about-banner m-0 of-h">
        <div class="row">
            <div class="col-12 col-lg-12 ">
                <div class="section-head">
                    <p class="font-lato-medium mb-3 fs-2">
                        About Us
                    </p>
                    <p class="font-jost-regular fs-7 font-weight-bold ">Inspiring Bright Minds</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: 'AboutCardHeadImg',
    props: {
        details: { type: Array, default: null }
    },
    components: {},
    methods: {
        banner (image) {
            return { backgroundImage: 'url(' + image + ')' };
        }
    }
};
</script>
<style>
.about-banner {
    background-image: url('../../assets/img/web/about-us/bgi.jpg');
    min-height: 45vh;
    background-size: cover;
    background-position: 0% 58%;

}

.section-head {
    margin-top: 8.3rem;
    color: white;
    text-align: center;
}

@media (max-width: 750px) {
    .about-banner {
        padding: 0;
        align-items: center;
        background-position: center 100%;
        background-size: cover;
        min-height: 35vh;
        width: 100%;

    }

    .section-head {
        text-align: center;
        padding-top: 2rem;

    }

}

@media (min-width: 768px) and (max-width: 1024px) {
    .section-head {
        text-align: center;
        padding-top: 10.3rem;

    }

    .about-banner {
        background-image: url('../../assets/img/web/about-us/bgi.jpg');
        min-height: 45vh;
        background-size: cover;
        background-position: 40% 50%;
    }
}
</style>
