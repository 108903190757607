 <template>
    <div>
        <div class="card">
            <div class="fl-te-c">
                <h4 class="mb-3">NEWS DETAILS</h4>
                <btn text="Back" @click="$router.push('/admin/news/')" size="sm"/>
            </div>

            <edit-news v-if="details" :initial-data="details"/>
            <p v-else class="text-center p-3">No data available.</p>
        </div>
        <news-image @success="loadDetails" :news="id" :details="details.news_images"/>
    </div>
</template>

<script>
import NewsImage from './NewsImageListing';
import axios from 'secure-axios';
import urls from '../../../data/urls';
import EditNews from './EditNews';

export default {
    name: 'DetailsPage',
    components: { EditNews, NewsImage },
    data () {
        return {
            loading: false,
            details: '',
            id: this.$route.params.id
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        async loadDetails () {
            this.loading = true;
            const response = await axios.form(urls.admin.news.details, { id: this.id });
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
                this.details.category = json.data.category.id;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.loading = false;
        }
    }
};
</script>

<style scoped>

</style>
