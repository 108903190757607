<template>

    <div class="pos-r">
        <swiper v-if="details.length!==0" class="swiper swiper-primary pb-5" :options="swiperOption">
            <swiper-slide v-for="(item, index) in details" :key="index">
                <our-facilities-item :item="item"></our-facilities-item>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
        </swiper>
        <swiper v-else class="swiper swiper-primary pb-5" :options="swiperOption">
            <swiper-slide>
                <our-facilities-item></our-facilities-item>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
        </swiper>
        <div class="d-sm-none swiper-btn swiper-button-prev pr-md-4 pl-md-3  px-2 fs--1" slot="button-prev"></div>
        <div class="d-sm-none swiper-btn swiper-button-next px-md-4 px-2" slot="button-next"></div>

    </div>

</template>
<script> import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import OurFacilitiesItem from '../../components/home/OurFacilitiesItem';

export default {
    name: 'OurFacilitySwiper',
    props: {
        details: {
            type: Array, default: null
        }
    },
    components: { OurFacilitiesItem, Swiper, SwiperSlide },
    data () {
        return {
            swiperOption: {
                autoHeight: true,
                slidesPerView: 1,
                spaceBetween: 0,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                mousewheel: false,
                parallax: true,
                centeredSlides: true,
                // autoplay: true,
                autoplay: { delay: 8000, disableOnInteraction: false },
                navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
                breakpoints: {
                    1200: {
                        speed: 1500
                    },
                    1024: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        speed: 1000
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        speed: 800
                    },
                    640: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                    320: { slidesPerView: 1, spaceBetween: 0, speed: 800 }
                }

            },
            items: []
        };
    }
}; </script>
<style lang="scss" scoped>
main {
    //background: #f2f2f2;
}

.swiper-btn {
    position: absolute;
    z-index: 799 !important;
    color: rgba(128, 128, 128, 0.7) !important;
    border: 1px solid rgba(128, 128, 128, 0.9);
    background-color: var(--color-white);
    padding: var(--spacer-2);
    font-size: 0.9rem !important;

    &.swiper-button-prev {
        left: -2.5rem;
    }

    &.swiper-button-next {
        right: -2.5rem;
    }
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    width: unset;
    padding: 3px 25px;
    background: #2929293b;
    border-radius: 15px;
}

</style>
