<template>
    <vue-table-card title="Contact Us Requests" :show-search-box="false" :fields="fields" :url="listUrl" :per-page="10"
                    ref="table">
        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn size="sm" text="view" @click="viewDetails(rowData)"/>
                <delete-btn @click="setDelete(rowData)"/>
            </div>
        </template>

        <template #footer>
            <modal title="Details" ref="detailsModal" width="40r" header-color="primary" no-close-on-backdrop>
                <div class="row">
                    <div class="col-6 font-weight-bold">Name</div>
                    <div class="col-6"><span class="pr-3"> : </span>{{ details.name }}</div>
                </div>
                <div class="row">
                    <div class="col-6 font-weight-bold">E-Mail</div>
                    <div class="col-6"><span class="pr-3"> : </span>{{ details.email }}</div>
                </div>
                <div class="row">
                    <div class="col-6 font-weight-bold">Phone Number</div>
                    <div class="col-6"><span class="pr-3"> : </span>{{ details.phone_number }}</div>
                </div>
                <div class="row">
                    <div class="col-6 font-weight-bold">Training Program</div>
                    <div class="col-6"><span class="pr-3"> : </span>{{ details.training_program }}</div>
                </div>
                <div class="row">
                    <div class="col-6 font-weight-bold">Training Center</div>
                    <div class="col-6"><span class="pr-3"> : </span>{{ details.training_center }}</div>
                </div>
                <div class="row">
                    <div class="col-6 font-weight-bold">Message</div>
                    <div class="col-6"><span class="pr-3"> : </span>{{ details.message }}</div>
                </div>
                <div class="row">
                    <div class="col-6 font-weight-bold">Created At</div>
                    <div class="col-6"><span class="pr-3"> : </span>{{ details.created_at }}</div>
                </div>
            </modal>

            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                <p>You are about to delete the item <b v-html="deletingItem && deletingItem.name"></b>. Are you sure ?
                </p>

                <template #loading>
                    <loading-animation/>
                    Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                </template>
            </delete-modal>
        </template>

    </vue-table-card>
</template>

<script>
import urls from '../../../data/urls';

export default {
    name: 'ContactUsRequestListing',
    data () {
        return {
            listUrl: urls.admin.contactUs.list,
            deleteUrl: urls.admin.contactUs.delete,
            details: '',
            deletingItem: null,
            fields: [
                {
                    name: 'name',
                    sortField: 'name',
                    title: 'Name'
                },
                {
                    name: 'email',
                    sortField: 'email',
                    title: 'E-Mail'
                },
                {
                    name: 'phone_number',
                    sortField: 'phone_number',
                    title: 'Phone'
                },
                {
                    name: 'created_at',
                    sortField: 'created_at',
                    title: 'Created At'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ]
        };
    },
    methods: {
        viewDetails (item) {
            this.details = { ...item };
            this.$refs.detailsModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        }
    }
};
</script>
