<template>
    <div>
        <div id="top-banner">
            <base-banner :img="[]" height="half"></base-banner>
        </div>
        <div class="container py-5">
            <inner-page-card :horz-padding="false" :link-back="true" color="basic" heading="My Courses">
                <template #default>
                    <tabs class="c-tabs-white" align="center">
                        <tab title="Registered Course">
                            <individual-registered-courses :details="myRegisteredCourses"></individual-registered-courses>
                        </tab>
                        <tab title="Attended Course">
                            <individual-attended-courses :details="myAttendedCourses"></individual-attended-courses>
                        </tab>
                    </tabs>
                </template>
            </inner-page-card>
        </div>
    </div>
</template>

<script>
import InnerPageCard from '@components/home/my-training-programs/InnerPageCard';
import bannerImg from '../../../assets/web/course-offered/bgi.jpg';
import BaseBanner from '@components/ui/BaseBanner';
import IndividualRegisteredCourses from '../../../components/my-training-programs/IndividualRegisteredCourses';
import IndividualAttendedCourses from '../../../components/my-training-programs/IndividualAttendedCourses';
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'MyCoursesListPage',
    components: { IndividualAttendedCourses, IndividualRegisteredCourses, InnerPageCard, BaseBanner },
    data () {
        return {
            bannerImg: bannerImg,
            myRegisteredCourses: '',
            myAttendedCourses: '',
            dataLoading: false,
            dataLoading1: false
        };
    },

    beforeMount () {
        this.loadRegisteredCourses();
        this.loadAttendedCourses();
    },

    methods: {
        async loadRegisteredCourses () {
            this.dataLoading = true;
            const response = await axios.get(urls.website.course.myRegisteredCourses);
            if (response.data.permission === false) {
                this.$notify('Sorry, Permission denied to the requested page', 'Permission Denied', {
                    type: 'danger'
                });
                await this.$router.push('/');
            } else {
                this.myRegisteredCourses = response.data;
                this.dataLoading = false;
                this.$emit('loadingSuccess');
            }
        },
        async loadAttendedCourses () {
            this.dataLoading1 = true;
            const response = await axios.get(urls.website.course.myAttendedCourses);
            this.myAttendedCourses = response.data;
            this.dataLoading1 = false;
            this.$emit('loadingSuccess');
        }
    }
};
</script>

<style scoped>

</style>
