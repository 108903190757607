<template>
    <data-loading-component v-if="dataLoading"/>
    <div v-else>
        <div v-if="details">
            <div id="banner-slider">
                <home-banner-slider v-if="details.banners" :details="details.banners"></home-banner-slider>
            </div>
            <div>
                <whats-new-scrolling :details="details.whats_new"></whats-new-scrolling>
            </div>
            <div id="why-choose-us">
                <why-choose-us v-if="details.why_choose_us" :details="details.why_choose_us"></why-choose-us>
            </div>
            <div id="training-centers">
                <training-centers v-if="details.training_centers.length!==0"
                                  :details="details.training_centers"></training-centers>
            </div>
            <div id="our-courses">
                <our-courses v-if="details.our_courses.length!==0" :details="details.our_courses"></our-courses>
            </div>
            <div id="request-training">
                <request-training></request-training>
            </div>
            <div id="our-facilities">
                <our-facilities v-if="details.our_facility.length!==0" :details="details.our_facility"></our-facilities>
            </div>
            <div id="home-bottom-banner">
                <home-bottom-banner></home-bottom-banner>
            </div>
        </div>
    </div>
</template>

<script>
import WhatsNewScrolling from '../components/home/WhatsNewScrolling';
import WhyChooseUs from '../components/home/WhyChooseUs';
import TrainingCenters from '../components/home/TrainingCenters';
import OurCourses from '../components/home/OurCourses';
import RequestTraining from '../components/home/RequestTraining';
import HomeBannerSlider from '../components/home/HomeBannerSlider';
import OurFacilities from '../components/home/OurFacilities';
import HomeBottomBanner from '../components/home/HomeBottomBanner';
import axios from 'secure-axios';
import urls from '../data/urls';
import DataLoadingComponent from '../components/ui/DataLoadingComponent';

export default {
    name: 'Home',

    components: {
        DataLoadingComponent,
        HomeBottomBanner,
        OurFacilities,
        HomeBannerSlider,
        RequestTraining,
        OurCourses,
        TrainingCenters,
        WhyChooseUs,
        WhatsNewScrolling
    },

    data () {
        return {
            details: '',
            dataLoading: false
        };
    },

    beforeMount () {
        this.loadDetails();
    },

    methods: {
        async loadDetails () {
            this.dataLoading = true;
            const response = await axios.get(urls.website.home);
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
            this.$emit('loadingSuccess');
        }
    }
};

</script>
<style scoped>

</style>
