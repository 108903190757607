<template>
    <div v-if="details.length!==0" class="contact-banner" :style="banner(details[0].banner_image)">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-12 ">
                    <div class="section-head">
                        <p class="font-lato-medium mb-2 fs-2">
                            {{details[0].heading1}}
                        </p>
                        <p class="font-jost-semi-bold fs-7 font-weight-bold ">{{details[0].heading2}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="contact-banner">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-12 ">
                    <div class="section-head">
                        <p class="font-lato-medium mb-2 fs-2">
                            Start with
                        </p>
                        <p class="font-jost-semi-bold fs-7 font-weight-bold ">Professional Offline Course For Achievers</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Courseoffered-Banner',
    props: {
        details: { type: Array, default: null }

    },
    components: {},
    methods: {
        banner (image) {
            return { backgroundImage: 'url(' + image + ')' };
        }
    }
};
</script>
<style>
.contact-banner {
    background-image: url('../../assets/img/web/contact us/bgi.jpg');
    min-height: 45vh;
    background-size: cover;
    background-position: 0% 50%;
}

.section-head {
    margin-top: 8.3rem;
    color: white;
    text-align: center;
}

@media (max-width: 750px) {
    .contact-banner {
        padding: 0;
        background-position: center 100%;
        background-size: cover;
        min-height: 35vh;
        width: 100%;
    }
}
</style>
