<template>
    <div class="request-banner pos-r fl-x-cc py-5">
        <div class="overlay-dark"></div>
        <div class="container">
            <div class="text-center fl-y fl-a-c text-white z-index-4  pb-4 pt-5">
                <h3 class="font-jost-semi fs-9 mb-4">Request For Training</h3>
                <!--                <p class="fs-lg&#45;&#45;1 px-lg-9 mb-5 text-white-1 font-lato-medium">Lorem ipsum dolor sit amet, consectetur-->
                <!--                    adipisicing elit. Accusantium, amet-->
                <!--                    autem cum-->
                <!--                    deleniti dolores doloribus ipsa iusto libero magni, maiores modi molestias natus nesciunt nisi-->
                <!--                    numquam obcaecati officia placeat possimus praesentium provident quia quo repellendus sint tempora-->
                <!--                    voluptas voluptate voluptates.-->
                <!--                </p>-->
                <div @click="$refs.requestModal.show()">
                    <btn-underline underline-color="primary" class="text-white-1" :icon-border-round="true"
                                   :icon-img="btnIconImg" text="REQUEST A TRAINING">
                    </btn-underline>
                </div>
            </div>
            <!--            <btn @click="$refs.popUpModal.show()">success modal</btn>-->
        </div>
        <modal class="c-modal no-close header-primary" no-close-on-backdrop title="Request A Training"
               ref="requestModal" width="30r" header-color="">
            <div class="row fl-x-bc mt-3">
                <div class="col-12 col-lg-10 pb-3">
                    <b-form class="input-placeholder-black" :save-url="requestTrainingUrl"
                            @success="formSuccess" v-slot="{model, loading}">
                        <div class="">
                            <validated-ajax-vue-select size="sm" border-radius="0"
                                                       class="c-input-select-b c-select-field"
                                                       aria-placeholder="Select Program"
                                                       :url="trainingProgramOptions" :rules="{required: true}"
                                                       v-model="model.training_program" :disabled="loading"
                                                       placeholder="Choose A Training Program"/>
                            <!--                            <custom-validated-ajax-select size="sm" border-radius="0"-->
                            <!--                                                          class="c-input-select-b c-select-field"-->
                            <!--                                                          aria-placeholder="Select Program" :disabled="loading"-->
                            <!--                                                          placeholder="Choose A Training Program"/>-->
                        </div>

                        <div class="">
                            <custom-validated-form-select size="sm" border-radius="0"
                                                          class="c-input-select-b c-select-field"
                                                          v-model="model.training_type" :rules="{required: true}"
                                                          aria-placeholder="Select Type" :options="trainingTypeOptions"
                                                          placeholder="Training Type (Individual/Organization)"></custom-validated-form-select>
                        </div>

                        <div v-if="model.training_type === 'Individual'" class="">
                            <validated-input size="sm" placeholder="Enter Name" border-radius="0"
                                             v-model="model.name" :rules="{required: true}" class="mb-3 c-input-2"/>
                        </div>

                        <div v-if="model.training_type === 'Organization'" class="">
                            <validated-input size="sm" placeholder="Name of Organization" border-radius="0"
                                             v-model="model.organization_name" :rules="{required: true}"
                                             class="c-input-2 mb-3"/>
                        </div>
                        <div v-if="model.training_type === 'Organization'" class="">
                            <validated-input size="sm" class="c-input-2" type="number"
                                             placeholder="Number of Participants"
                                             border-radius="0" name="phone" v-model="model.number_of_participants"
                                             id="participants" :rules="{required: true}">
                            </validated-input>
                        </div>
                        <div>
                            <validated-input size="sm" type="email" placeholder="Email ID" class="c-input-2"
                                             border-radius="0" name="email"
                                             id="email" :rules="{required: true}">
                            </validated-input>

                        </div>
                        <div class="">
                            <validated-input size="sm" type="number" class="c-input-2" placeholder="Phone Number"
                                             border-radius="0" name="phone" v-model="model.phone_number"
                                             id="phonenumber" :rules="{required: true, max: 10, min: 10}">
                            </validated-input>
                        </div>
                        <div class="row pb-3">
                            <div class="col-12">
                                <p class="text-black-1 fs-lg-1 mb-2">Preferred Training Time</p>
                            </div>
                            <div class="col-lg-6 pr-2">
                                <validated-date-picker value-type="YYYY-MM-DD" format="DD-MM-YYYY" size="sm"
                                                       placeholder="From"
                                                       border-radius="0" name="From date"
                                                       style="color: white !important;"
                                                       :disabled-date="disabledDateTodayAndBefore"
                                                       class="py-1 c-date-picker c-input-2" v-model="model.from_date"
                                                       :rules="{required: true}">
                                </validated-date-picker>
                            </div>
                            <div class="col-lg-6 pl-2">
                                <validated-date-picker value-type="YYYY-MM-DD" format="DD-MM-YYYY" size="sm"
                                                       placeholder="To" border-radius="0"
                                                       name="To date" style="color: white !important;"
                                                       :disabled-date="disabledDateTodayAndBefore"
                                                       class="py-1 c-date-picker c-input-2" v-model="model.to_date"
                                                       :rules="{required: true}">
                                </validated-date-picker>
                            </div>
                        </div>
                        <div class="mt-0 pt-4 btn-group pos-r">
                            <div class="bt-dashed pos-a-t c-line">&nbsp;</div>
                            <btn type="button" @click="$refs.requestModal.close()"
                                 border-radius="0" text="Close"
                                 class="px-51 py-3 fs--1 font-lato-medium" design="basic-b"></btn>

                            <btn border-radius="0" type="submit" text="Submit"
                                 class="px-51 py-3 fs--1 font-lato-medium ml-3"></btn>
                        </div>
                    </b-form>
                </div>
            </div>
        </modal>
        <modal class="c-modal no-close header-primary" no-close-on-backdrop title="Request A Training"
               ref="popUpModal" width="25r" header-color="">
            <request-training-submited @close="$refs.popUpModal.close()"></request-training-submited>
        </modal>
    </div>
</template>

<script>
import BtnUnderline from '@components/ui/BtnUnderline';
import btnIconImg from '../../assets/web/landing/request-training/req.png';
import urls from '../../data/urls';
import RequestTrainingSubmited from '../popup-modals/Request-Training-Submitted';
import CustomValidatedFormSelect from '@components/lego-custom/custom-ajax-select/CustomValidatedFormSelect';

export default {
    name: 'RequestTraining',
    components: { CustomValidatedFormSelect, RequestTrainingSubmited, BtnUnderline },
    data () {
        return {
            trainingProgramOptions: urls.admin.course.vueSelect,
            trainingTypeOptions: [
                { label: 'Individual', value: 'Individual' },
                { label: 'Organization', value: 'Organization' }
            ],
            requestTrainingUrl: urls.admin.trainingRequest.request,
            btnIconImg: btnIconImg
        };
    },
    methods: {
        formSuccess () {
            this.$refs.requestModal.close();
            this.$refs.popUpModal.show();
        },
        disabledDateTodayAndBefore (date) {
            const today = new Date();
            return date < today;
        }
    }
};
</script>

<style scoped lang="scss">
.underline-primary {
    position: relative;
    padding-bottom: var(--spacer-2);

    &::after {
        content: '';
        position: absolute;
        height: 1.7px;
        bottom: 0;
        background: var(--color-primary);
        width: 100%;
    }
}

.round-before {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        border: 1.5px solid var(--color-primary);
    }
}

.round-primary {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid var(--color-primary);
}

//.overlay-dark{
//    position: absolute;
//    width: 100%;
//    height: 100%;
//    background: rgba(0,0,0,0.4);
//}
.request-banner {
    background: url("../../assets/web/landing/request-training/bg.png");
    background-size: cover;
    background-position: center;
    min-height: 40vh;
}

.bg-dark-trans {
    background-color: rgba(0, 0, 0, 0.4);
}

.c-line {
    width: 142% !important;
    left: -67px;
}
</style>
