<template>
    <s-form ref="form">
        <div class="row">
            <div class="col-12">
                <validated-vue-select label="Type" v-model="model.type" :options="typeOptions"
                                      :rules="{required: true}" :disabled="loading" select-first/>
            </div>
            <div class="col-12" v-if="model.type">
                <validated-input label="Title" v-model="model.title"
                                 :rules="{required: true, max:100}" :disabled="loading"/>
            </div>
            <div class="col-12" v-else>
                <file-input class="c-file-input" ref="file" label="Image" v-model="model.image"
                            :disabled="loading" name="Image">
                </file-input>
                <image-info :width="730" :height="300"/>
            </div>
            <div class="col-12">
                <ckeditor class="form-control" v-model="model.description" name="Description"
                          :editor="editor" :config="editorConfig" @input="descriptionRequired=false"></ckeditor>
                <p v-if="descriptionRequired" class="text-danger">The description field is required</p>
            </div>
            <div class="col-6">
                <validated-checkbox label="Is Enabled" v-model="model.is_enabled"
                                    :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn @click="submitClicked" class="trn" text="Save" loading-text="Saving..." size="sm"
                     :disabled="loading" :loading="loading"/>
            </div>
        </div>
    </s-form>
</template>

<script>

import urls from '../../../../data/urls';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import axios from 'secure-axios';

export default {
    props: {
        course: { type: String }
    },
    components: {
        ckeditor: CKEditor.component
    },
    name: 'AddCourseOverview',

    data () {
        return {
            loading: false,
            descriptionRequired: false,
            model: {},
            addUrl: urls.admin.course.detailsUrl.overview.addEdit,
            typeOptions: [
                { label: 'Title', value: true },
                { label: 'Image', value: false }
            ],
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'insertTable',
                        '|',
                        'undo',
                        'redo'
                    ]
                },
                table: {
                    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
                },
                language: 'en'
            }
        };
    },

    methods: {
        async submitClicked () {
            this.model.course = this.$route.params.id;
            if (!this.model.description) {
                this.descriptionRequired = true;
                return;
            }
            this.loading = true;
            const response = await axios.form(this.addUrl, this.model);
            const json = response.data;
            if (!json) {
                this.formError(json);
            } else if (json.error === false) {
                this.formSuccess(json);
            } else {
                this.formError(json);
            }
            this.loading = false;
        },
        formError (json) {
            if (json.errors) {
                this.$refs.form.setErrors(json.errors);
            } else {
                this.$notify('Could not save, Please try again later.', 'Error',
                    {
                        type: 'danger'
                    }
                );
            }
            this.$emit('error');
        },
        formSuccess () {
            this.$notify('Successfully Added Item..!', 'Success',
                {
                    type: 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
