<template>
    <div class="fl-x fl-a-c cursor-pointer">
        <div :class="{'round-primary':iconBorderRound}">
            <img :src="iconImg" alt="" v-if="iconImg" style="height: 2rem;">
            <slot name="icon" v-else></slot>
        </div>
        <div class="c-underline ml-2" :class="underlineColor">
            <p class="m-0 btn-fs">{{ text }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BtnUnderline',
    props: {
        text: {
            type: String,
            default: ''
        },
        underlineColor: {
            type: String,
            default: 'black'
        },
        iconImg: {
            type: String,
            default: null
        },
        iconBorderRound: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style scoped lang="scss">
.c-underline {
    position: relative;
    padding-bottom: var(--spacer-2);

    &.primary {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            height: 1.7px;
            bottom: 0;
            left: 0;
            background: var(--color-primary);
            width: 100%;
        }
    }

    &.black {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            height: 1.2px;
            bottom: 0;
            left:0;
            background: var(--color-black);
            width: 100%;
        }
    }
    &.white {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            height: 1.2px;
            bottom: 0;
            left:0;
            background: var(--color-white);
            width: 100%;
        }
    }

}

.btn-fs {
    font-size: 0.82rem;
    @media (min-width: 992px) {
        font-size: 0.9rem;
    }

}

.round-primary {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 1.5px solid var(--color-primary);
}
</style>
