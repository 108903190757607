<template>
    <s-form ref="form">
        <div class="row">
            <div class="col-12">
                <validated-vue-select label="Type" v-model="model.type" :options="typeOptions"
                                      :rules="{required: true}" :disabled="loading || checkIfPublished" select-first/>
            </div>
            <div class="col-12" v-if="model.type">
                <validated-input label="Title" v-model="model.title"
                                 :rules="{required: true, max:100}" :disabled="loading || checkIfPublished"/>
            </div>
            <div class="col-12" v-else>
                <div v-if="!checkIfPublished">
                    <file-input class="c-file-input" ref="file" label="Image" v-model="model.image"
                                :disabled="loading || checkIfPublished" name="Image">
                    </file-input>
                    <image-info :width="730" :height="300"/>
                </div>
                <div v-else>
                    Course Overview Image<br/>
                    <btn type="button" class="btn-block mt-2 mb-3" title="Click to view image."
                         @click="viewFile(initialData.image)"/>
                </div>
            </div>
            <div class="col-12">
                <ckeditor class="form-control" v-model="model.description" :disabled="loading || checkIfPublished"
                          :editor="editor" :config="editorConfig" @input="descriptionRequired=false"></ckeditor>
                <p v-if="descriptionRequired" class="text-danger">The description field is required</p>
            </div>
            <div class="col-6">
                <validated-checkbox label="Is Enabled" v-model="model.is_enabled"
                                    :rules="{required: false}" :disabled="loading || checkIfPublished"/>
            </div>
            <div class="col-12" v-if="!checkIfPublished">
                <btn @click="submitClicked" class="trn" text="Update" loading-text="Updating..." size="sm"
                     :disabled="loading" :loading="loading"/>
            </div>
        </div>
    </s-form>
</template>

<script>
import urls from '../../../../data/urls';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import axios from 'secure-axios';

export default {
    name: 'EditCourseOverview',

    props: {
        initialData: { type: Object },
        checkIfPublished: { type: Boolean, default: false }
    },
    components: {
        ckeditor: CKEditor.component
    },
    beforeMount () {
        this.model = { ...this.initialData };
    },
    data () {
        return {
            addUrl: urls.admin.course.detailsUrl.overview.addEdit,
            loading: false,
            descriptionRequired: false,
            model: {},
            typeOptions: [
                { label: 'Title', value: true },
                { label: 'Image', value: false }
            ],
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'insertTable',
                        '|',
                        'undo',
                        'redo'
                    ]
                },
                table: {
                    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
                },
                language: 'en'
            }
        };
    },

    methods: {
        clearFile (file) {
            this.initialData.banner_image = '';
        },
        viewFile (file) {
            window.open(file);
        },
        async submitClicked () {
            if (!this.model.description) {
                this.descriptionRequired = true;
                return;
            }
            this.formatData();
            this.loading = true;
            this.model.course = this.$route.params.id;
            const response = await axios.form(this.addUrl, this.model);
            const json = response.data;
            if (!json) {
                this.formError(json);
            } else if (json.error === false) {
                this.formSuccess(json);
            } else {
                this.formError(json);
            }
            this.loading = false;
        },
        formatData () {
            if (this.model.type) {
                this.model.image = '';
            } else {
                this.model.title = '';
            }
        },
        formSuccess () {
            this.$notify('Successfully Updated Item..!', 'Success',
                {
                    type: 'success'
                }
            );
            this.$emit('success');
        },
        formError (json) {
            if (json.errors) {
                this.$refs.form.setErrors(json.errors);
            } else {
                this.$notify('Could not save, Please try again later.', 'Error',
                    {
                        type: 'danger'
                    }
                );
            }
            this.$emit('error');
        }
    }
};
</script>

<style scoped>

</style>
