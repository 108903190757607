<template>
    <vue-table-card title="Philosophy" :show-search-box="false" :fields="fields" :url="listUrl" :per-page="10" ref="table">
        <template #buttons>
            <btn color="success" icon="fa fa-plus" size="sm" @click="$refs.addModal.show()" text="Add"/>
        </template>
        <template #image="{rowData}">
            <div class="container-image" @click="viewFile(rowData.image)">
                <img :src="rowData.image" alt="Avatar" class="c-image">
                <div class="c-overlay">
                    <div class="c-icon">
                        <i class="fa fa-eye"></i>
                    </div>
                </div>
            </div>
        </template>
        <template #is_enabled="{rowData}">
            <p v-if="rowData.is_enabled">Yes</p>
            <p v-else>No</p>
        </template>

        <template #actions="{rowData}">
            <div class="btn-group-tight">
                <btn size="xs" text="View" @click="viewDetails(rowData.id)"/>
                <delete-btn @click="setDelete(rowData)"/>
            </div>
        </template>

        <template #footer>
            <modal title="Add Philosophy" ref="addModal" width="40r" header-color="primary" no-close-on-backdrop>
                <add-philosophy @success="formSuccess"/>
            </modal>

            <modal title="Edit Philosophy" ref="editModal" width="40r" header-color="primary" no-close-on-backdrop>
                <edit-philosophy :initial-data="editingItem" @success="formSuccess"/>
            </modal>

            <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
                <p>You are about to delete the item <b v-html="deletingItem && deletingItem.name"></b>. Are you sure ?
                </p>

                <template #loading>
                    <loading-animation/>
                    Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
                </template>
            </delete-modal>
        </template>

    </vue-table-card>
</template>

<script>
import urls from '../../../../data/urls';
import AddPhilosophy from './AddPhilosophy';
import EditPhilosophy from './EditPhilosophy';

export default {
    name: 'Philosophy',
    components: { EditPhilosophy, AddPhilosophy },
    data () {
        return {
            listUrl: urls.admin.philosophy.list,
            addUrl: urls.admin.philosophy.addEdit,
            deleteUrl: urls.admin.philosophy.delete,
            editingItem: null,
            deletingItem: null,
            fields: [
                {
                    name: 'order',
                    sortField: 'order',
                    title: 'Order'
                },
                {
                    name: '__slot:image',
                    title: 'Image'
                },
                {
                    name: 'created_at',
                    sortField: 'created_at',
                    title: 'Created At'
                },
                {
                    name: '__slot:is_enabled',
                    title: 'Enabled'
                },
                {
                    name: '__slot:actions',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ]
        };
    },
    methods: {
        viewFile (file) {
            window.open(file);
        },
        viewDetails (id) {
            this.$router.push('/admin/philosophy/' + id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.editModal.close();
            refs.table.refreshTable();

            this.editingItem = null;
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$refs.table.refreshTable();
        }
    }
};
</script>
