var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-table-card',{ref:"table",attrs:{"title":"News Category","fields":_vm.fields,"url":_vm.listUrl,"per-page":10},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('btn',{attrs:{"color":"success","icon":"fa fa-plus","size":"sm","text":"Add"},on:{"click":function($event){return _vm.$refs.addModal.show()}}})]},proxy:true},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"btn-group-tight"},[_c('delete-btn',{on:{"click":function($event){return _vm.setDelete(rowData)}}}),_c('edit-btn',{on:{"click":function($event){return _vm.setEdit(rowData)}}})],1)]}},{key:"footer",fn:function(){return [_c('modal',{ref:"addModal",attrs:{"title":"Add News Category","width":"30r","header-color":"primary","no-close-on-backdrop":""}},[_c('b-form',{attrs:{"save-url":_vm.addUrl},on:{"success":_vm.formSuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('validated-input',{staticClass:"required-field",attrs:{"label":"Category Name","rules":{required:true},"disabled":loading},model:{value:(model.category_name),callback:function ($$v) {_vm.$set(model, "category_name", $$v)},expression:"model.category_name"}}),_c('btn',{attrs:{"text":"Save","loading-text":"Saving...","size":"sm","disabled":loading,"loading":loading}})]}}])})],1),_c('modal',{ref:"editModal",attrs:{"title":"Edit News Category","width":"30r","header-color":"primary","no-close-on-backdrop":""}},[_c('b-form',{attrs:{"save-url":_vm.addUrl,"initial-data":_vm.editingItem},on:{"success":_vm.formSuccess},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
var loading = ref.loading;
return [_c('validated-input',{attrs:{"label":"Category Name","rules":{required:true},"disabled":loading},model:{value:(model.category_name),callback:function ($$v) {_vm.$set(model, "category_name", $$v)},expression:"model.category_name"}}),_c('btn',{attrs:{"text":"Update","loading-text":"Updating...","size":"sm","disabled":loading,"loading":loading}})]}}])})],1),_c('delete-modal',{ref:"deleteModal",attrs:{"url":_vm.deleteUrl,"params":_vm.deletingItem},on:{"response":_vm.deleteComplete},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading-animation'),_vm._v(" Please wait while we delete "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.deletingItem && _vm.deletingItem.name)}}),_vm._v(". ")]},proxy:true}])},[_c('p',[_vm._v("You are about to delete the category "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.deletingItem && _vm.deletingItem.category_name)}}),_vm._v(". Are you sure ?")])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }