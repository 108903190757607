<template>
    <div>
        <div v-for="(i, index) in details.applicants" :key="index" class="p-2 bg-4 grid-layout-1">
            <p class="text-center">{{ index + 1 }}</p>
            <p class="text-left">{{ i.name }}</p>
            <p class="text-left">{{ i.gender_name }}</p>
            <p class="text-left">{{ i.dob }}</p>
            <p class="text-left">{{ i.email }}</p>
            <div class="btn-group" v-if="showActionButton && details.application_status!=='Application Closed'
                                    && details.payment_status !== 'Transaction Completed'
                                    && details.is_online_payment">
                <btn design="" @click="editClicked(i)">
                    <img src="../../../assets/web/course-offered/edit.png" alt="" class="icon-sm">
                </btn>
                <btn design="" @click="deleteClicked(i, index)">
                    <img src="../../../assets/web/course-offered/delete.png" alt="" class="icon-sm">
                </btn>
            </div>
        </div>
        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the user <b v-html="deletingItem && deletingItem.name"></b>. Are you sure ?
            </p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
            </template>
        </delete-modal>
    </div>
</template>

<script>
import urls from '../../../data/urls';

export default {
    name: 'UserAddedItem',
    props: {
        details: {
            type: Object,
            default: null
        },
        showActionButton: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            deletingItem: null,
            deleteUrl: urls.website.course.coursesOrganizationUserDelete
        };
    },
    methods: {
        editClicked (item) {
            this.$emit('editClicked', item);
        },

        deleteClicked (item, index) {
            this.deletingItem = { ...item };
            this.deletingItem.index = index;
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            try {
                this.details.applicants.splice(this.deletingItem.index, 1);
                this.deletingItem = null;
                this.$refs.deleteModal.close();
                this.$notify('Deleted Successfully', 'Success', {
                    type: 'success'
                });
                this.$emit('loadDetails');
            } catch (error) {
                console.error('Error :', error);
                this.$notify('Failed to delete the user. Please try again.', 'Failed', {
                    type: 'danger'
                });
            }
        }
    }
};
</script>

<style scoped lang="scss">
.grid-layout-1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (min-width: 992px) {
        grid-template-columns:1fr repeat(3, 2fr) 2fr 2fr;
    }
}
</style>
