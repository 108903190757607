<template>
    <div class="row fl-x-cc mt-3">
        <div class="col-12 col-lg-9 mb-5">
            <s-form class="">
                <div class="fl-x-cc im">
                   <img src="../../assets/img/web/about-us/tick1.gif">
                </div>
                <p class="font-lato-bold fs-3 mb-2 fl-x-cc margin-t">Payment Success!</p>

                <div class=" fl-x-cc m-t">
                    <btn border-radius="0" text="Close" class="px-5 py-2" design="basic-b"></btn>
                </div>
            </s-form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Payment-Success'
};
</script>

<style scoped lang="scss">
.im img{
    //height:200px;
    width:300px;
}
.margin-t{
    margin-top:-26px!important;
}
.m-t{
    margin-top:1.8rem!important;
}
</style>
