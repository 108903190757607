<template>
    <div class="mt-3 mt-lg-0 heading-container min-h" :class="{'min-h-lg':size==='lg'}">
        <!--        <div class="mb-2 text-danger">-->
        <!--            <i>{{ source }}</i>-->
        <!--            <div v-if="author!==''" class="text-primary fs&#45;&#45;1"><span class="mr-2"><i class="fa fa-user"></i></span>{{ author }}</div>-->
        <!--        </div>-->
        <h6
            class="font-jost-semi mb-3" :class="{'lg-heading': titleSize === 'lg'}" v-if="heading !== ''">{{
                heading
            }}</h6>
        <slot name="header" v-else></slot>
        <!--        <span class="bottom-bar" :class="{'bottom-bar-lg': titleSize === 'lg'}"></span>-->
        <div class="font-lato-medium">
            <slot name="content"></slot>
        </div>
    </div>
</template>
<script> export default {
    name: 'ShortSection',
    props: {
        heading: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: 'sm'
        },
        source: {
            type: String,
            default: ''
        },
        author: {
            type: String,
            default: ''
        },
        titleSize: {
            type: String,
            default: 'sm'
        }
    }
}; </script>
<style scoped lang="scss"> .heading-container {
    text-align: center;

    .bottom-bar {
        background-color: var(--color-primary);
        height: 0.3125rem;
        width: 3.125rem;
        margin: 0.4rem 0;
        display: inline-block;
    }

    .bottom-bar-lg {
        width: 6.125rem;
    }

    @media (min-width: 992px) {
        text-align: left;
    }
}

//.min-h {
//    min-height: 18.7rem !important;
//}
//.min-h-lg{
//    min-height: 22.7rem !important;
//}
</style>
