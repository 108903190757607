<template>
    <div v-if="item" class="home-banner pos-r fl-x fl-j-c pb-5"
         :class="{'fl-a-c':contentAlign==='center','fl-a-e':contentAlign==='bottom'}"
         :style="banner(item.banner_image)">
        <div class="overlay-dark"></div>
        <div class="text-center text-white z-index-4 px-lg-7 px-3 pb-2 pb-lg-4 pt-4 pt-lg-5 bg-dark-trans">
            <h3 class="font-jost-semi fs-lg-10 mb-3">{{ item.heading1 }}</h3>
            <h5 class="fs-lg-9 font-jost-book mb-2">{{ item.heading2 }}</h5>
            <p class="fs-lg-1 font-lato-medium">{{ item.heading3 }}
            </p>
            <btn @click="redirectToViewMore(item.url)" border-radius="0" class="mt-2 c-btn-1 font-lato-regular text-uppercase"
                 :text="item.url_text"></btn>
<!--            <btn @click="$router.push('/about-us/')" border-radius="0" class="mt-2 c-btn-1 font-lato-regular text-uppercase"-->
<!--                 text="know more"></btn>-->
        </div>
    </div>
    <div v-else class="home-banner-1 pos-r fl-x fl-j-c pb-5"
         :class="{'fl-a-c':contentAlign==='center','fl-a-e':contentAlign==='bottom'}">
        <div class="overlay-dark"></div>
        <div class="text-center text-white z-index-4 px-lg-7 px-3 pb-2 pb-lg-4 pt-4 pt-lg-5 bg-dark-trans">
            <h3 class="font-jost-semi fs-lg-10 mb-3">Welcome to KLDB Training Courses.</h3>
            <h5 class="fs-lg-9 font-jost-book mb-2">Redefine Your Trajectory For An Illustrious Career</h5>
            <p class="fs-lg-1 font-lato-medium">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <btn @click="$router.push('/about-us/')" border-radius="0"
                 class="mt-2 c-btn-1 font-lato-regular text-uppercase"
                 text="Know More"></btn>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HomeBanner',
    props: {
        contentAlign: {
            type: String,
            default: 'bottom'
        },
        item: { type: Object, default: null }

    },
    methods: {
        banner (image) {
            return { backgroundImage: 'url(' + image + ')' };
        },
        redirectToViewMore (url) {
            window.open(url);
        }
    }
};
</script>

<style scoped lang="scss">
.overlay-dark {
    position: absolute;
    width: 100%;
    height: 115%;
    background: rgba(0, 0, 0, 0.4);
}

.home-banner {
    background-size: cover;
    background-position: center;
    min-height: 50vh;
    @media (min-width: 992px) {
        min-height: 90vh;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        min-height: 50vh;
    }
}

.home-banner-1 {
    background: url("../../assets/web/landing/baner.jpg");
    background-size: cover;
    background-position: center;
    min-height: 50vh;
    @media (min-width: 992px) {
        min-height: 90vh;
    }
    @media (min-width: 768px) and (max-width: 1024px) {
        min-height: 50vh;
    }
}

.bg-dark-trans {
    background-color: rgba(0, 0, 0, 0.4);
}
</style>
