<template>
    <div>

        <div class="container ">
            <div class="row">
                <div class="col-12 col-lg-11 p-0">
                    <div title="Faculty pr-lg-5">
                        <p class="font-jost-semi fs-lg-1">LEARN WITH THE BEST</p>
                        <p class="font-lato-medium mb-5 pr-lg-7 text-justify">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut
                            delectus fugit
                            illum libero modi numquam soluta temporibus totam ut voluptates!</p>
                    </div>

                </div>
            </div>
            <div v-for="(i, index) in details" :key="index" class="row">
                <div class=" col-4 col-lg-2 im-2 col-md-2 p-0">
                    <img src="../../assets/web/landing/our-courses/Male Avatar.jpg" alt="">
                </div>
                <div class="col-7 col-md-6 col-lg-4 mt-1">
                    <p class=" ml-1 font-jost-semi-bold">Name: <span class="font-lato-medium"> {{ i.name }}</span></p>
                    <p class="ml-1  font-jost-semi-bold">Designation: <span class="font-lato-medium"> {{ i.designation }}</span></p>
                    <p class=" ml-1 font-jost-semi-bold">Location: <span class="font-lato-medium"> {{ i.location.location }}</span></p>

                </div>

                    <div class="col-11 col-lg-11 p-0">
                        <p class="font-lato-medium mb-5 pt-1 mt-1">{{i.description}}</p>
                    </div>

            </div>
        </div>
<!--                    <btn @click="$refs.NotLoggedInModal.show()">Open Modal</btn>-->
        <modal class="c-modal" :no-close-on-backdrop="false" title=""
               ref="NotLoggedInModal" width="25r" header-color="">
            <div class="text-center px-lg-5 pb-5 pt-4">
                <div class="fl-x fl-j-c mb-4">
                    <div class="custom-info-icon"
                         style="">
                        i
                    </div>
                </div>

                <p class="font-lato-semi-bold fs-lg-4 mb-4">You're not logged in !</p>
                <p class="fs--1 mb-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo, natus! Lorem
                    ipsum
                    dolor sit.</p>
                <router-link to="/login/">
                    <btn text="LOGIN NOW" border-radius="0" class="px-5"></btn>
                </router-link>
            </div>
        </modal>
    </div>
</template>

<script>
// import BaseBanner from '@components/ui/BaseBanner';
import bannerImg from '../../assets/web/course-offered/bgi.jpg';
import img1 from '../../assets/web/landing/our-courses/course-1.jpg';
import img2 from '../../assets/web/landing/our-courses/course-2.jpg';
import img3 from '../../assets/web/landing/our-courses/course-3.jpg';

export default {
    name: 'Faculty',
    props: {
        details: {
            type: Array, default: null
        }
    },
    components: {},
    data () {
        return {
            bannerImg: bannerImg,
            img1: img1,
            img2: img2,
            img3: img3,
            incidentFields: [
                {
                    name: 'particulars',
                    title: 'Particulars'
                },
                {
                    name: 'days',
                    title: 'Days'
                },
                {
                    name: 'rate',
                    title: 'Rate/Unit'
                },
                {
                    name: 'rate1',
                    title: 'Rate/Unit'
                }
            ],
            dataItems: [
                {
                    particulars: 'Lodging Charges',
                    days: 10,
                    rate: 'Rs. 100/day/person',
                    rate1: '1000'
                },
                {
                    particulars: 'Boarding Charges',
                    days: 10,
                    rate: 'Rs. 500/day/person',
                    rate1: '5000'
                },
                {
                    particulars: 'Tuition Fee',
                    days: 10,
                    rate: 'Rs. 25000/person',
                    rate1: '25000'
                }
            ]
        };
    }
};
</script>

<style scoped lang="scss">
.im-2 img {
    height: 100px;
    width: 100px;
}

.pos-a-left-ol {
    @media (min-width: 992px) {
        position: absolute;
        width: 70%;
        left: -4rem;
        top: -5rem;
    }

}
</style>
