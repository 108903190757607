<template>
    <div>
        <swiper v-if="details.length!==0" class="swiper home-slider swiper-basic" data-mousewheel="1" data-parallax="1"
                :options="swiperOption">
            <swiper-slide v-for="(item, index) in details" :key="index">
                <home-banner :item="item" class="d-lg-none-sm-fl"></home-banner>
                <home-banner :item="item" class="d-sm-none-lg-fl" content-align="center"></home-banner>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
        </swiper>
        <swiper v-else class="swiper home-slider swiper-basic" data-mousewheel="1" data-parallax="1"
                :options="swiperOption">
            <swiper-slide>
                <home-banner class="d-lg-none-sm-fl"></home-banner>
                <home-banner class="d-sm-none-lg-fl" content-align="center"></home-banner>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
        </swiper>
    </div>
</template>
<script> import 'swiper/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import HomeBanner from '../../components/home/HomeBanner';

export default {
    name: 'HomeBannerSlider',
    props: {
        details: { type: Array, default: null }
    },
    components: { HomeBanner, Swiper, SwiperSlide },
    data () {
        return {
            swiperOption: {
                autoHeight: true,
                slidesPerView: 1,
                spaceBetween: 0,
                pagination: { el: '.swiper-pagination', clickable: true },
                mousewheel: false,
                parallax: false,
                centeredSlides: true,
                // autoplay: true,
                autoplay: { delay: 8000, disableOnInteraction: false },
                navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
                breakpoints: {
                    1200: { speed: 1500 },
                    1024: { slidesPerView: 1, spaceBetween: 0, speed: 1000 },
                    768: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                    640: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                    320: { slidesPerView: 1, spaceBetween: 0, speed: 800 }
                }
            }
        };
    }
}; </script>
}
}
<style scoped lang="scss"> .item {
    width: 100%;
    display: flex;
    align-items: center;
}

.overlay-dark {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}

/*.bg-home-banner {*/
/*    background-image: url('../../assets/img/social-banner/home-banners-mob.jpg');*/
/*}*/

/*.bg-home-banner2 {*/
/*    background-image: url('../../assets/img/social-banner/home-banners-mob2.jpg');*/
/*}*/

/*.bg-home-banner3 {*/
/*    background-image: url('../../assets/img/social-banner/home-banners-mob3.jpg');*/
/*}*/

.home-banner-icon {
    display: inline-block;
}

.home-banner-icon img {
    width: 1.2rem;
    margin-left: 20px;
}

.home-slider h1 {
    line-height: 40px;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {

    .swiper-pagination-bullet {
        border: 1px solid var(--color-white) !important;

    }

}

.swiper-pagination-bullet {
    border: 1px solid var(--color-white) !important;

}

/*.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {*/
/*    bottom: 10px;*/
/*    left: 50%;*/
/*    transform: translate(-50%, 0);*/
/*    width: unset;*/
/*    padding: 3px 25px;*/
/*    background: #2929293b;*/
/*    border-radius: 15px;*/
/*}*/

/*@media (min-width: 992px) {*/
/*    .bg-home-banner {*/
/*        background-image: url('../../assets/img/social-banner/home-banners.jpg');*/
/*    }*/

/*    .bg-home-banner2 {*/
/*        background-image: url('../../assets/img/social-banner/home-banners2.jpg');*/
/*    }*/

/*    .bg-home-banner3 {*/
/*        background-image: url('../../assets/img/social-banner/home-banners3.jpg');*/
/*    }*/

/*    .home-banner-icon img {*/
/*        width: 2rem;*/
/*        margin-left: 20px;*/
/*    }*/
/*}*/
</style>
