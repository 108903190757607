<template>
    <div>
        <div id="top-banner">
            <base-banner :img="[]" height="half"></base-banner>
        </div>
        <individual-profile-update v-if="currentUser.user_type==='Individual'"/>
        <organization-profile-update v-if="currentUser.user_type==='Organization'"/>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseBanner from '../../components/ui/BaseBanner';
import IndividualProfileUpdate from './components/IndividualProfileUpdate';
import OrganizationProfileUpdate from './components/OrganizationProfileUpdate';

export default {
    name: 'ProfileUpdate',
    components: { BaseBanner, OrganizationProfileUpdate, IndividualProfileUpdate },
    computed: {
        ...mapGetters(['currentUser'])
    }
};
</script>

<style scoped>

</style>
