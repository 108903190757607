<template>
    <div class="card bs-4">
        <div class="fl-te-c mb-3">
            <h4>Training Center Images</h4>
            <btn color="success" icon="fa fa-plus" size="sm" @click="$refs.addModal.show()" text="Add"/>
        </div>
        <simple-table :data="details" :fields="fields" ref="table">
            <template #image="{rowData}">
                <div class="container-image" @click="viewFile(rowData.image)">
                    <img :src="rowData.image" alt="Avatar" class="c-image">
                    <div class="c-overlay">
                        <div class="c-icon">
                            <i class="fa fa-eye"></i>
                        </div>
                    </div>
                </div>
            </template>
            <template #is_thumbnail1="{rowData}">
                <p v-if="rowData.is_thumbnail1">Yes</p>
                <p v-else>No</p>
            </template>
            <template #is_enabled="{rowData}">
                <p v-if="rowData.is_enabled">Yes</p>
                <p v-else>No</p>
            </template>
            <template #is_thumbnail2="{rowData}">
                <p v-if="rowData.is_thumbnail2">Yes</p>
                <p v-else>No</p>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <edit-btn @click="setEdit(rowData)"/>
                    <delete-btn @click="setDelete(rowData)"/>
                </div>
            </template>
        </simple-table>
        <modal title="Add Image" ref="addModal" width="30r" header-color="primary" no-close-on-backdrop>
            <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}"
                    :save-params="{training_center}">
                <div class="row">
                    <div class="col-12">
                        <file-input class="c-file-input" label="Image" v-model="model.image" :disabled="loading"/>
                        <image-info :height="690" :width="1100"/>
                    </div>
                    <div class="col-12">
                        <validated-input type="number" label="Order" v-model="model.order"
                                         :rules="{required: false, min_value:1}" :disabled="loading"/>
                    </div>
                    <div class="col-6">
                        <validated-checkbox label="Is Thumbnail 1" v-model="model.is_thumbnail1" :disabled="loading"/>
                    </div>
                    <div class="col-6">
                        <validated-checkbox label="Is Thumbnail 2" v-model="model.is_thumbnail2" :disabled="loading"/>
                    </div>
                    <div class="col-6">
                        <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                            :rules="{required: false}" :disabled="loading"/>
                    </div>
                    <div class="col-12 text-right">
                        <btn class="trn" text="Save" loading-text="Saving..." size="sm" :disabled="loading"
                             :loading="loading"/>
                    </div>
                </div>
            </b-form>
        </modal>
        <modal title="Edit Image" ref="editModal" width="30r" header-color="primary" no-close-on-backdrop>
            <b-form :save-url="addUrl" @success="formSuccess1" v-slot="{model, loading}"
                    :save-params="{training_center}"
                    :initial-data="editingItem">
                <div class="row">
                    <div class="col-12">
                        <file-input class="c-file-input" ref="file" @input="clearFile" label="Image"
                                    v-model="model.image"
                                    :disabled="loading">
                            <template #append>
                                <div class="btn-group">
                                    <btn v-if="editingItem.image"
                                         @click="viewFile(editingItem.image)"
                                         type="button" class="fa fa-eye" size="xs"/>
                                </div>
                            </template>
                        </file-input>
                        <image-info :height="690" :width="1100"/>
                    </div>
                    <div class="col-12">
                        <validated-input type="number" label="Order" v-model="model.order"
                                         :rules="{required: false, min_value:1}" :disabled="loading"/>
                    </div>
                    <div class="col-6">
                        <validated-checkbox label="Is Thumbnail 1" v-model="model.is_thumbnail1" :disabled="loading"/>
                    </div>
                    <div class="col-6">
                        <validated-checkbox label="Is Thumbnail 2" v-model="model.is_thumbnail2" :disabled="loading"/>
                    </div>
                    <div class="col-6">
                        <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                            :rules="{required: false}" :disabled="loading"/>
                    </div>
                    <div class="col-12 text-right">
                        <btn class="trn" text="Update" loading-text="Updating..." size="sm" :disabled="loading"
                             :loading="loading"/>
                    </div>
                </div>
            </b-form>
        </modal>

        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the image <b v-html="deletingItem && deletingItem.name"></b>. Are you sure ?</p>

            <template #loading>
                <loading-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
            </template>
        </delete-modal>
    </div>
</template>

<script>
import urls from '@/data/urls';

export default {
    props: {
        training_center: { type: String },
        details: { type: Array, default: null }
    },
    name: 'TrainingCenterImage',
    data () {
        return {
            addUrl: urls.admin.trainingCenter.imageAddEdit,
            deleteUrl: urls.admin.trainingCenter.imageDelete,
            editingItem: null,
            deletingItem: null,
            fields: [
                {
                    name: 'order',
                    title: 'Order'
                },
                {
                    name: '__slot:image',
                    title: 'Image'
                },
                {
                    name: '__slot:is_thumbnail1',
                    title: 'Whether Thumbnail 1'
                },
                {
                    name: '__slot:is_thumbnail2',
                    title: 'Whether Thumbnail 2'
                },
                {
                    name: '__slot:is_enabled',
                    title: 'Enabled'
                },
                {
                    name: '__slot:actions',
                    title: 'Action',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ]
        };
    },
    methods: {
        clearFile (file) {
            this.editingItem.image = '';
        },
        viewFile (file) {
            window.open(file);
        },
        formSuccess () {
            this.$notify('Added Successfully', 'Success', {
                type: 'success'
            });
            const refs = this.$refs;
            refs.addModal.close();
            this.$emit('success');
        },

        formSuccess1 () {
            this.$notify('Updated Successfully', 'Success', {
                type: 'success'
            });
            const refs = this.$refs;
            refs.editModal.close();
            this.$emit('success');
        },

        setEdit (item) {
            this.editingItem = { ...item };
            this.$refs.editModal.show();
        },

        setDelete (item) {
            this.deletingItem = { ...item };
            this.deletingItem.child = this.training_center;
            this.$refs.deleteModal.show();
        },

        deleteComplete (response) {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$notify('Deleted Successfully', 'Success', {
                type: 'success'
            });
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
