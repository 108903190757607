<template>
    <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
        <div class="row">
            <div class="col-12">
                <validated-vue-select label="Banner Type" class="required-field" v-model="model.page"
                                      :options="bannerTypeOptions"
                                      :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <file-input class="c-file-input" ref="file" label="Banner Image" v-model="model.banner_image"
                            :disabled="loading" name="Banner Image">
                </file-input>
                <image-info v-if="model.page==='Home'" :width="1920" :height="770"/>
                <image-info v-if="model.page==='About Us'" :width="1920" :height="770"/>
                <image-info v-if="model.page==='Courses Offered'" :width="1920" :height="770"/>
                <image-info v-if="model.page==='Training Centers'" :width="1920" :height="770"/>
                <image-info v-if="model.page==='Contact Us'" :width="1920" :height="770"/>
                <image-info v-if="model.page==='News'" :width="1920" :height="770"/>
            </div>
            <div class="col-12">
                <validated-input v-if="model.page!=='News' && model.page!=='Contact Us'"
                                 label="Banner Text 1" v-model="model.heading1" class="required-field"
                                 :rules="{required: true, max:35}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-input v-if="model.page!=='News' && model.page!=='Contact Us'"
                                 label="Banner Text 2" v-model="model.heading2" class="required-field"
                                 :rules="{required: true, max:50}" :disabled="loading"/>
            </div>
            <div class="col-12">
                <validated-input v-if="model.page==='Home'" label="Banner Text 3" v-model="model.heading3"
                                 class="required-field" :rules="{required: true, max:110}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input v-if="model.page==='Home'" label="Url" v-model="model.url"
                                 class="required-field" :rules="{required: true}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input v-if="model.page==='Home'" label="Url Text" v-model="model.url_text"
                                 class="required-field" :rules="{required: true, max:15}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-input type="number" label="Order" v-model="model.order"
                                 :rules="{required: false, min_value:1}" :disabled="loading"/>
            </div>
            <div class="col-6">
                <validated-checkbox label="Enabled" v-model="model.is_enabled"
                                    :rules="{required: false}" :disabled="loading"/>
            </div>
            <div class="col-12 text-right">
                <btn class="trn" text="Save" loading-text="Saving..." size="sm" :disabled="loading" :loading="loading"/>
            </div>
        </div>
    </b-form>
</template>

<script>

import urls from '../../../../data/urls';

export default {
    name: 'AddBanner',

    data () {
        return {
            addUrl: urls.admin.banner.addEdit,
            bannerTypeOptions: [
                { label: 'Home', value: 'Home' },
                { label: 'About Us', value: 'About Us' },
                { label: 'Courses Offered', value: 'Courses Offered' },
                { label: 'Training Centers', value: 'Training Centers' },
                { label: 'Contact Us', value: 'Contact Us' },
                { label: 'News', value: 'News' }
            ]
        };
    },

    methods: {
        formSuccess () {
            this.$notify('Successfully Added Banner..!', 'Success',
                {
                    type: 'success'
                }
            );
            this.$emit('success');
        }
    }
};
</script>

<style scoped>

</style>
