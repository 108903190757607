<template>
    <!--suppress HtmlUnknownTag -->
    <div class="ld-over" :class="[{'running': dataLoading}]" v-if="hideIfNoData ? data.length > 0 : true">
        <div class="ld ld-ring ld-spin" v-if="dataLoading"></div>
        <table class="vuetable table">
            <TableHeader :fields="fields"/>
            <transition-group appear :name="animation" class="vuetable-body" tag="tbody">
                <tr v-for="(item, itemIndex) in data" :key="item[keyField] || itemIndex">
                    <TableCell
                        v-for="(field, fieldIndex) in fields"

                        :key="fieldIndex"
                        :class-name="field.dataClass"
                        :row-index="itemIndex"
                        :field="field"
                        :item="item"

                        @cellClicked="onCellClicked"
                        @cellRightClicked="onCellRightClicked"
                        @cellDoubleClicked="onCellDoubleClicked"

                    >
                        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
                            <slot :name="slot" v-bind="scope"/>
                        </template>
                    </TableCell>
                </tr>
            </transition-group>
        </table>
        <div class="pr-4 w-100 text-right py-2">
            <span>Total Amount  &nbsp;:&nbsp; </span>
            <span>&#8377; </span>
            <span class="w-100 text-right">{{ total }}</span>
        </div>
        <div class="pr-4 w-100 text-right py-2">
            <span>Total Amount including tax <span v-if="tax_percentage!==0">({{ tax_percentage }}%)</span> &nbsp;:&nbsp; </span>
            <span>&#8377; </span>
            <span class="w-100 text-right">{{ ((tax_percentage / 100) * total) + total }}</span>
        </div>
    </div>
</template>

<script>

import TableCell from './TableCell';
import TableHeader from './TableHeader';

export default {
    name: 'custom-simple-table',
    components: { TableHeader, TableCell },
    props: {
        animation: { type: String, default: 'fade' },
        fields: { type: Array, required: true },
        keyField: { type: String, default: undefined, required: false },
        data: { type: Array, required: true },
        showBorder: { type: Boolean, default: false },
        hideIfNoData: { type: Boolean, default: false },
        total: { type: Number, default: null },
        tax_percentage: { type: Number, default: null }

    },

    data: function () {
        return {
            eventPrefix: 'simpleTable:',
            dataLoading: false
        };
    },

    beforeMount () {
    },

    methods: {
        showLoadingAnimation () {
            this.dataLoading = true;
        },

        hideLoadingAnimation () {
            this.dataLoading = false;
        },

        getTitle (field) {
            if (typeof (field.title) === 'function') return field.title();

            return typeof (field.title) === 'undefined'
                ? field.name.replace('.', ' ')
                : field.title;
        },

        getObjectValue (object, path, defaultValue) {
            defaultValue = (typeof defaultValue === 'undefined') ? null : defaultValue;

            let obj = object;
            if (path.trim() !== '') {
                const keys = path.split('.');
                keys.forEach(function (key) {
                    if (obj !== null && typeof obj[key] !== 'undefined' && obj[key] !== null) {
                        obj = obj[key];
                    } else {
                        obj = defaultValue;
                    }
                });
            }
            return obj;
        },

        /*
        * Utils
        * */
        extractArgs (string) {
            return string.split(':')[1];
        },
        extractName (string) {
            return string.split(':')[0].trim();
        },

        /*
        * Events
        * */
        onCellClicked (dataItem, field, event) {
            this.$emit(this.eventPrefix + 'cell-clicked', dataItem, field, event);
        },
        onCellDoubleClicked (dataItem, field, event) {
            this.$emit(this.eventPrefix + 'cell-dblclicked', dataItem, field, event);
        },
        onCellRightClicked (dataItem, field, event) {
            this.$emit(this.eventPrefix + 'cell-rightclicked', dataItem, field, event);
        }

    }
};
</script>
