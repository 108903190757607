<template>
    <div>
        <div class="container py-5">
            <inner-page-card :horz-padding="false" :link-back="true" color="basic" heading="Profile Update">
                <template #default>
                    <div class="px-lg-5 px-3 py-3">
                        <div class="fl-te-c">
                            <h5 class="font-jost-semi-bold pb-4">Basic Information</h5>
                            <btn class="px-5 bs-21" text="Edit" v-if="!editable"
                                 type="button" @click="editable = true"/>
                        </div>
                        <b-form :save-url="profileUpdateUrl" @success="formSuccess" v-slot="{model, loading}"
                                :initial-data="initialData">
                            <div class="row">
                                <div class="col-lg-12">
                                    <validated-input label="Organization Name*"
                                                     name="Organization Name" id="Organization Name"
                                                     v-model="model.organization_name" :disabled="loading || !editable"
                                                     :rules="{ required:true}"
                                                     class="font-jost-regular c-input-2">
                                    </validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-vue-select name="Organization Type" class="font-jost-regular c-input-2"
                                                          :disabled="loading || !editable"
                                                          :options="typeOptions" v-model="model.organization_type"
                                                          label="Type Of Organization"></validated-vue-select>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Designation" name="Designation" id="Designation"
                                                     v-model="model.designation" :disabled="loading || !editable"
                                                     class="font-jost-regular c-input-2">
                                    </validated-input>
                                </div>
                                <div class="col-lg-12">
                                    <validated-input label="Name" name="Name"
                                                     id="name" v-model="model.name" :disabled="loading || !editable"
                                                     class="font-jost-regular c-input-2 bdr-radius">
                                    </validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Phone Number" name="Phone Number" type="number" id="Phone"
                                                     v-model="model.phone_number" :rules="{max: 10, min: 10}"
                                                     :disabled="loading || !editable"
                                                     class="num-hide font-jost-regular c-input-2">
                                    </validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Email Id" name="Email" type="email" v-model="model.email"
                                                     :disabled="loading || !editable"
                                                     class="font-jost-regular c-input-2">
                                    </validated-input>
                                </div>
                                <div class="col-lg-12">
                                    <validated-input label="House/ Flat No./ Building/Apartment"
                                                     name="Address" id="Address"
                                                     class="font-jost-regular c-input-2"
                                                     v-model="model.address" :disabled="loading || !editable">
                                    </validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Area/Street/Village" name="Area" id="Area"
                                                     v-model="model.area"
                                                     :disabled="loading || !editable"
                                                     class="font-jost-regular c-input-2">
                                    </validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input label="Town/City" name="City" id="City" v-model="model.city"
                                                     :disabled="loading || !editable"
                                                     class="font-jost-regular c-input-2">
                                    </validated-input>
                                </div>
                                <div class="col-lg-6">
                                    <validated-ajax-vue-select name="State" class="font-jost-regular c-input-2"
                                                               :disabled="loading || !editable" :url="stateOptions"
                                                               v-model="model.state" label="State"/>
                                </div>
                                <div class="col-lg-6">
                                    <validated-input name="Pincode" label="Pincode" v-model="model.pincode"
                                                     :disabled="loading" type="number"
                                                     class="num-hide font-jost-regular c-input-2">
                                    </validated-input>
                                </div>
                                <div class="col-lg-12 fl-x-tr mt-4" v-if="editable">
                                    <btn design="basic-b" @click="editable = false" border-radius="0"
                                         class="px-5 py-2 mr-3">close
                                    </btn>
                                    <btn border-radius="0" :loading="loading" loading-text="Updating..." class="px-5">
                                        Update
                                    </btn>
                                </div>
                            </div>
                        </b-form>
                    </div>
                </template>
            </inner-page-card>
        </div>

    </div>
</template>

<script>
import InnerPageCard from '../../../components/home/my-training-programs/InnerPageCard';
import urls from '../../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name: 'OrganizationProfileUpdate',
    components: { InnerPageCard },
    computed: {
        ...mapGetters(['currentUser'])
    },
    data () {
        return {
            editable: false,
            initialData: {},
            stateOptions: urls.masterData.State.stateOptions,
            typeOptions: [
                { label: 'Type 1', value: 'Type 1' },
                { label: 'Type 2', value: 'Type 2' }
            ],
            profileUpdateUrl: urls.auth.profileUpdate
        };
    },
    beforeMount () {
        this.setInitialData();
    },
    methods: {
        setInitialData () {
            if (!this.currentUser.id) {
                this.$router.push('/login/');
            }
            this.initialData = { ...this.currentUser };
        }
    }
};
</script>

<style scoped>

</style>
