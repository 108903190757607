<template>
    <div class="w-100 fl-y fl-a-c" v>
        <div class="w-h-7re pos-r">
            <div v-if="img"
                 class="border-rounded outline-primary w-h-100 of-h">
                    <img :src="img.thumbnail1" alt="" class="w-h-100">
            </div>
            <!--            <img v-else src="../../assets/web/landing/training-fields/trr.png" alt=""-->
            <!--                 class="border-rounded outline-primary w-h-100"/>-->
        </div>
        <div v-if="img" class="text-white mt-2">
            <div class="fl-x">
                <img src="../../assets/web/landing/map-filled.png" class="icon-sm" alt="">
                <span class="font-inter-semi-bold ml-2 fs-lg--1">{{ img.location }}</span>
            </div>
            <div class="ml-3 text-white-1">
                <span class="fs-lg--1 font-inter-regular">{{ img.district_name }}</span>
                <span class="fs-lg--1 font-inter-regular">, {{ img.state_name }}</span>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'TrainingCenterItem',
    props: {
        img: {
            type: Object,
            default: null
        }
    },
    methods: {
        banner (image) {
            return { backgroundImage: 'url(' + image + ')' };
        }
    }
};
</script>

<style scoped>
.w-5re {
    width: 5rem;
    height: 5rem;
}
</style>
