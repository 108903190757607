<template>
    <div class="im-c">
        <div class="container">
            <div class="row fl-x-bc ">
                <div class="col-12 col-lg-6">
                    <p class="fs-4 font-jost-semi mt-6" align="center">Training Schedule For
                        {{ details.financial_year }}
                    </p>
                    <p class="fs-1 font-lato-regular" align="center">{{details.description}}</p>
                    <div class="fl-y-cc pb-5">
                        <img alt="Training Period Image" class="im-c fl-x-cc"
                             :src="details.image_file">
<!--                        <img alt="Courses Dates Image" class="d-sm-none"-->
<!--                             src="../../assets/web/course-offered/courses-image.png">-->
<!--                        <img alt="Courses Dates Image" class="d-lg-none"-->
<!--                             src="../../assets/web/course-offered/courses-image-responsive.png">-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TrainingSchedule',
    props: {
        details: {
            type: Object,
            default: null
        }
    }
};
</script>

<style scoped>
.im-c img {
    width: 80%;
    height: 80%;
}

.im-c {
    /*background-color: #F8F8F8*/
    background-color: #F5F5F5;
}
</style>
