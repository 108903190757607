<template>
    <div v-if="details.length!==0" class="training-banner of-h" :style="banner(details[0].banner_image)">
        <div class="row">
            <div class="col-12 col-lg-12 ">
                <div class="section-head">
                    <p class="font-inter-medium mb-3 fs-2 text-white">
                        {{details[0].heading1}}
                    </p>
                    <p class=" fs-7 font-jost-semi-bold text-white">{{details[0].heading2}}</p>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="training-banner of-h">
        <div class="row">
            <div class="col-12 col-lg-12 ">
                <div class="section-head">
                    <p class="font-inter-medium mb-3 fs-2 text-white">
                        Training Center
                    </p>
                    <p class=" fs-7 font-jost-semi-bold text-white">We Provide Multiple Training Center</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'TrainingCenterBanner',
    props: {
        details: { type: Array, default: null }
    },
    components: {},
    methods: {
        banner (image) {
            return { backgroundImage: 'url(' + image + ')' };
        }
    }
};
</script>
<style scoped>
.training-banner {
    background-image: url('../../assets/img/web/about-us/bgi.jpg');
    min-height: 45vh;
    background-size: cover;
    background-position: 0% 50%;
}

.section-head {
    margin-top: 8.3rem;
    color: white;
    text-align: center;
}

@media (max-width: 750px) {
    .training-banner {
        padding: 0;
        margin: 0;
        background-position: center 100%;
        background-size: cover;
        min-height: 35vh;
        width: 100%;
    }

    .section-head {
        text-align: center;
        padding-top: 2rem;

    }
}
 @media (min-width: 768px) and (max-width: 1024px) {
     .section-head {
        text-align: center;
        padding-top: 10.3rem!important;

    }
    .training-banner {
    background-image: url('../../assets/img/web/about-us/bgi.jpg');
    min-height: 45vh;
    background-size: cover;
    background-position: 40% 50%;
}
 }
</style>
