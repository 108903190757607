<template>
    <div>
        <div class="container py-5">
            <div class="row">
                <div class="col-12">
                    <section-heading title="OUR FACILITY"></section-heading>
                </div>
                <div class="col-12">
                    <h2 class="font-jost-semi fs-lg-9 text-center mb-51 mt-3">
                        We Should Provide Appropriate Tagline
                    </h2>
                </div>
                <div class="col-12">
                    <our-facility-swiper v-if="details" :details="details"></our-facility-swiper>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OurFacilitySwiper from '../../components/home/OurFacilitySwiper';
export default {
    name: 'OurFacilities',
    props: {
        details: {
            type: Array, default: null
        }
    },
    components: { OurFacilitySwiper }
};
</script>

<style scoped>

</style>
