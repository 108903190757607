<template>
    <div class="row" v-if="details">
        <div class="col-12" v-if="details.data.length!==0">
            <course-item v-for="(i, index) in details.data" :key="index" :details="i"
                         card-type="org" class="mb-3"></course-item>
        </div>
        <div v-else class="col-12">
            <p class="text-center font-inter-medium">You haven't registered to any Courses.</p>
        </div>
        <div class="col-12 mt-3" v-if="details.data.length!==0">
            <custom-pagination :current-page="details.current_page" :last-page="details.last_page"/>
        </div>
    </div>
</template>

<script>
import CourseItem from '../../../components/training-programs/CourseItem';
import CustomPagination from '@components/ui/CustomPagination';

export default {
    name: 'OrganizationRegisteredCourses',
    props: {
        details: {
            type: Object
        }
    },
    components: { CustomPagination, CourseItem }
};
</script>

<style scoped>

</style>
