var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-table-card',{ref:"table",attrs:{"title":"About Us","show-search-box":false,"fields":_vm.fields,"url":_vm.listUrl,"per-page":10},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('btn',{attrs:{"color":"success","icon":"fa fa-plus","size":"sm","text":"Add"},on:{"click":function($event){return _vm.$refs.addModal.show()}}})]},proxy:true},{key:"image1",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"container-image",on:{"click":function($event){return _vm.viewFile(rowData.image1)}}},[_c('img',{staticClass:"c-image",attrs:{"src":rowData.image1,"alt":"Avatar"}}),_c('div',{staticClass:"c-overlay"},[_c('div',{staticClass:"c-icon"},[_c('i',{staticClass:"fa fa-eye"})])])])]}},{key:"image2",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"container-image",on:{"click":function($event){return _vm.viewFile(rowData.image2)}}},[_c('img',{staticClass:"c-image",attrs:{"src":rowData.image2,"alt":"Avatar"}}),_c('div',{staticClass:"c-overlay"},[_c('div',{staticClass:"c-icon"},[_c('i',{staticClass:"fa fa-eye"})])])])]}},{key:"is_enabled",fn:function(ref){
var rowData = ref.rowData;
return [(rowData.is_enabled)?_c('p',[_vm._v("Yes")]):_c('p',[_vm._v("No")])]}},{key:"actions",fn:function(ref){
var rowData = ref.rowData;
return [_c('div',{staticClass:"btn-group-tight"},[_c('btn',{attrs:{"size":"xs","text":"View"},on:{"click":function($event){return _vm.viewDetails(rowData.id)}}}),_c('delete-btn',{on:{"click":function($event){return _vm.setDelete(rowData)}}})],1)]}},{key:"footer",fn:function(){return [_c('modal',{ref:"addModal",attrs:{"title":"Add About Us","width":"40r","header-color":"primary","no-close-on-backdrop":""}},[_c('add-about-us',{on:{"success":_vm.formSuccess}})],1),_c('modal',{ref:"editModal",attrs:{"title":"Edit About Us","width":"40r","header-color":"primary","no-close-on-backdrop":""}},[_c('edit-about-us',{attrs:{"initial-data":_vm.editingItem},on:{"success":_vm.formSuccess}})],1),_c('delete-modal',{ref:"deleteModal",attrs:{"url":_vm.deleteUrl,"params":_vm.deletingItem},on:{"response":_vm.deleteComplete},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading-animation'),_vm._v(" Please wait while we delete "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.deletingItem && _vm.deletingItem.name)}}),_vm._v(". ")]},proxy:true}])},[_c('p',[_vm._v("You are about to delete the item "),_c('b',{domProps:{"innerHTML":_vm._s(_vm.deletingItem && _vm.deletingItem.name)}}),_vm._v(". Are you sure ? ")])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }